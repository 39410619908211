<template>
  <aside
    class="main-sidebar sidebar-dark-primary"
    style="height: 100%"
  >
    <!-- Brand Logo -->
    <router-link
      class="brand-link"
      :to="is_cockpit_route ? '' : '/'"
    >
      <img
        alt="ACISO"
        class="logo-aciso-menu"
        :src="`${publicPath}static/img/Tenacy_logo_Blanc.png`"
      />
      <img
        alt="ACISO"
        class="brand-image brand-image-collapse mt-2"
        :src="`${publicPath}static/img/Tenacy_logo_y_blanc.svg`"
      />
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <nav class="mt-2 nav-main-sidebar">
        <ul
          v-for="category in routes_categories"
          :key="category.name"
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          style="position: initial"
        >
          <li
            v-if="
              category.name !== 'myspace' &&
              category.children.filter(
                (e) =>
                  (e.meta.sidebar !== false && e.meta.is_authorized(e) !== false) ||
                  (e.meta.is_authorized(e) == false && e.meta.is_optional === true && !e.meta.has_option())
              ).length > 0
            "
            class="nav-header mt-4 text-uppercase text-white"
            style="border: 0px"
          >
            <span
              v-if="category.meta.i18nTitle"
              class="collapse-title"
            >
              {{ t(category.meta.i18nTitle) }}
            </span>
            <translate
              v-else
              class="collapse-title"
              >{{ category.meta.title }}</translate
            >
          </li>

          <li
            v-for="route in category.children.filter(
              (e) =>
                (e.meta.sidebar !== false && e.meta.is_authorized(e) !== false) ||
                (e.meta.is_authorized(e) == false && e.meta.is_optional === true && !e.meta.has_option())
            )"
            :key="route.name"
            class="nav-item hover-menu"
            :data-test-path="route.path"
          >
            <router-link
              v-slot="{ href }"
              custom
              :to="route"
            >
              <!-- Following href is trusted -->
              <a
                :id="route.name"
                class="nav-item hover-menu nav-link collapse-link"
                :class="{
                  active: activeRoute(route),
                  disabled: isDisabled(route),
                }"
                :href="href"
                @click.prevent="isDisabled(route) ? showModal() : do_refresh($event, route)"
                @mouseleave="hover_item = null"
                @mouseover="hover_item = route"
              >
                <b-row class="pr-1">
                  <b-col
                    class="justify-content-start collapse-icons collapse-icon"
                    cols="2"
                  >
                    <app-icon
                      class="icon-menu"
                      :class="{ 'icon-hover': hover_item === route, disabled: isDisabled(route) }"
                      :color="isDisabled(route) ? '#919191' : 'light'"
                      :custom_icon="route.meta.icon"
                      :fa_type="'far'"
                      :icon_size="'16px'"
                    />
                  </b-col>
                  <b-col
                    class="ml-2 collapse-title-bloc"
                    cols="8"
                  >
                    <span
                      class="text-white text-menu"
                      :class="{ disabled: isDisabled(route) }"
                    >
                      <template
                        v-if="
                          route.meta.onboarding_page &&
                          $store.getters.isGlobalPilote &&
                          $store.getters.onboarding_enabled === true
                        "
                      >
                        <translate key="start_up"> Start-up </translate>
                      </template>
                      <template v-else-if="route.meta.i18nTitle">
                        {{ t(route.meta.i18nTitle) }}
                      </template>
                      <translate
                        v-else
                        key="title"
                      >
                        {{ route.meta.title }}
                      </translate>
                    </span>
                  </b-col>
                  <b-col
                    class="collapse-title-bloc"
                    cols="1"
                  >
                    <span v-if="$store.getters.getTrialOption(route.meta.type)">
                      <span v-if="$store.getters.getTrialOption(route.meta.type).status === 'ON_TRIAL'">
                        <b-badge
                          class="badge-nb-days"
                          :style="{
                            'background-color': getColor(
                              Math.abs($store.getters.getTrialOption(route.meta.type).count_down)
                            ),
                          }"
                        >
                          {{ Math.abs($store.getters.getTrialOption(route.meta.type).count_down) }}j
                        </b-badge>
                      </span>
                      <span v-if="$store.getters.getTrialOption(route.meta.type).status === 'EXPIRED'">
                        <b-badge
                          class="badge-nb-days"
                          :style="{ 'background-color': '#FECD1E', color: '#343A40' }"
                        >
                          <i class="far fa-bell" />
                        </b-badge>
                      </span>
                    </span>
                  </b-col>
                </b-row>
              </a>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>

    <!--    isp  trial modal -->
    <project-security-upsell-modal />
    <trial-ended-modal option_type="ISP" />
    <!-- congratulations  modal -->
    <congratulations-modal />
  </aside>
</template>

<script>
import AppIcon from '@/components/ui/app-icon.vue'
import TrialEndedModal from '@/contexts/risks/risk-analysis/RiskAnalysisTrialEndedModal.vue'
import CongratulationsModal from '@/components/ui/congratulations-modal.vue'
import ProjectSecurityUpsellModal from '@/contexts/projects-security/project-security/ProjectSecurityUpsellModal.vue'

export default {
  name: 'main-sidebar',
  components: { ProjectSecurityUpsellModal, TrialEndedModal, AppIcon, CongratulationsModal },

  data() {
    return {
      hover_item: null,
      publicPath: import.meta.env.BASE_URL,
    }
  },
  computed: {
    routes_categories() {
      if (this.$store.getters.isPublic) {
        const routes_admin = this.$router.options.routes.find((e) => e.path === '/admin')
        return [routes_admin]
      } else {
        return this.$router.options.routes
          .find((e) => e.path === '/nav')
          .children.filter((e) => e.meta.is_authorized(e) === true)
      }
    },
    is_cockpit_route() {
      return this.$route.path.includes('home') || this.$route.name === 'customer'
    },
  },
  mounted: function () {
    const _this = this
    this.$root.$on('l2nav', function (r) {
      _this.do_refresh(null, r)
    })
  },
  methods: {
    showModal: function () {
      if (this.$store.getters.getTrialOption('ISP') !== null) {
        if (this.$store.getters.getTrialOption('ISP').status === 'EXPIRED') {
          this.$bvModal.show('trial-ended-modal')
        } else {
          this.$bvModal.show('isp_project_disabled')
        }
      } else {
        this.$bvModal.show('isp_project_disabled')
      }
    },
    hideUpsellModal() {
      this.$bvModal.hide('isp_project_disabled')
    },
    administrateRoute: function (route) {
      if (route.redirect) {
        return this.$route.matched.some((r) => r.path === route.redirect)
      }
      return false
    },
    activeRouteChildren: function (routes) {
      let found = false
      if (routes) {
        routes.forEach((e) => {
          if (e.name === this.$route.name) {
            found = true
          }
          if (e.children) {
            e.children.forEach((ee) => {
              if (ee.name === this.$route.name) {
                found = true
              }
              if (ee.children) {
                ee.children.forEach((eee) => {
                  if (eee.name === this.$route.name) {
                    found = true
                  }
                })
              }
            })
          }
        })
      }
      return found
    },
    activeRoute: function (route) {
      if (route.name === 'home' && this.$router.currentRoute.name === 'cockpit') {
        return true
      }
      return (
        this.$route.name === route.name || this.activeRouteChildren(route.children) || this.administrateRoute(route)
      )
    },
    isDisabled(route) {
      if (route.meta.is_optional) {
        return !route.meta.has_option()
      }
      return false
    },
    do_refresh: function (event, route) {
      const routeToGo = this.$router.resolve(route)
      const r = routeToGo.route.name

      if (event && event.ctrlKey === true) {
        window.open(routeToGo.href, '_blank')
        return false
      }
      let do_refresh = false
      if (r !== this.$route.name) {
        const route = this.$router.getRoutes().find((route) => route.name === this.$route.name)
        if (route.parent && route.parent.meta.multi && r === route.parent.name) {
          do_refresh = true
        }
      }
      if (r === this.$route.name || do_refresh) {
        let comp = null
        this.$root.$children.forEach((v1) => {
          if (v1.$refs.current_page) {
            comp = v1.$refs.current_page
          }
          if (!comp) {
            v1.$children.forEach((v2) => {
              if (v2.$refs.current_page) {
                comp = v2.$refs.current_page
              }
              if (!comp) {
                v2.$children.forEach((v3) => {
                  if (v3.$refs.current_page) {
                    comp = v3.$refs.current_page
                  }
                })
              }
            })
          }
        })
        if (comp) {
          if (comp && comp.$children.length > 0) {
            if (comp && comp.fetchData) {
              comp.fetchData()
            } else {
              comp = comp.$children[0]
              if (comp && comp.fetchData) {
                comp.fetchData()
              } else {
                comp = comp.$children[0]
                if (comp && comp.fetchData) {
                  comp.fetchData()
                }
              }
            }
          }
        }
      } else {
        this.$router.push(routeToGo.route).catch(() => {})
      }
    },
    getColor: function (days_left) {
      const period = 15
      if (days_left >= 0.5 * period) {
        return '#267C6E'
      } else if (days_left < 0.5 * period && days_left >= 0.25 * period) {
        return '#C65F00'
      } else {
        return '#FF0000'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$text-dark: #343a40;
$text-white: #fff;
$back-dark: #5d616687;

@media (max-width: 1600px) {
  body:not(.sidebar-mini-md) .content-wrapper,
  body:not(.sidebar-mini-md) .content-wrapper::before,
  body:not(.sidebar-mini-md) .main-footer,
  body:not(.sidebar-mini-md) .main-footer::before,
  body:not(.sidebar-mini-md) .main-header,
  body:not(.sidebar-mini-md) .main-header::before {
    margin-left: 0px;
  }
  .main-sidebar,
  .main-sidebar:before {
    z-index: 2000;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    margin-left: -160px;
  }

  .main-sidebar:hover {
    -webkit-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
    z-index: 2000;
    margin-left: 0px;

    .icon-menu {
      margin-left: 18px !important;
    }

    .logo-aciso-menu {
      display: block;
      width: 195px !important;
    }

    .brand-image-collapse {
      display: none;
    }

    .collapse-block {
      display: none;
    }

    .collapse-title {
      display: block;
    }

    .collapse-title-bloc {
      display: block;
      margin-left: -14px !important;
    }

    .collapse-icons {
      all: unset;
    }
  }

  .collapse-block {
    display: block;
  }
  .collapse-title {
    display: none;
  }
  .collapse-title-bloc {
    display: none;
  }

  .collapse-icons {
    margin-left: 171px;
  }

  .logo-aciso-menu {
    display: none;
  }
  .brand-image-collapse {
    display: block;
    margin-left: 165px;
  }
  .collapse-link {
    cursor: pointer;
  }
}

.os-scrollbar {
  display: none !important;
}

@media (min-width: 1600px) {
  body:not(.sidebar-mini-md) .content-wrapper,
  body:not(.sidebar-mini-md) .main-footer,
  body:not(.sidebar-mini-md) .main-header {
    -webkit-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
    margin-left: 71px !important;
  }
}

.sub-menu-aciso {
  left: 230px !important;
}

.separator {
  width: 100%;
  background-color: #4b545c;
  display: block;
  margin-bottom: 0.5rem;
}

.nav-sidebar .nav-header {
  font-size: 0.9rem;
  padding: 0 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px dashed #4b545c;
}

.menu-l2 {
  font-size: 0.9em;
  margin-left: 33px !important;
}

.layout-navbar-fixed.layout-fixed .wrapper .sidebar {
  margin-top: calc(3.5rem + 9px);
}

.sidebar {
  padding-right: 0;
  padding-left: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.nav-pills .nav-link {
  border-radius: 0px;
}

.nav-link:hover {
  span {
    color: $text-white;
  }
}

.sub-menu-aciso {
  border-radius: 2px;
  background-color: #484d54;
  position: absolute;
  left: 230px;
  width: 100%;
  padding: 5px;
  box-shadow: 11px 15px 20px rgba(0, 0, 0, 0.25) !important;
  transition: visibility 150ms linear 150ms, background 150ms linear 150ms;
}

.sub-menu-aciso-collapse {
  left: 74px !important;
  width: 250px;
}

.sidebar-mini.sidebar-collapse .main-sidebar {
  overflow-x: visible;
}

.dropdown-item:hover {
  background-color: #5b6066;
  color: $text-dark;

  a {
    color: $text-dark;
  }
}

.dropdown-item-active {
  background-color: #eeeeef;
  color: $text-dark;

  a {
    color: $text-dark;
  }
}

.dropdown-item-active:hover {
  span {
    color: $text-dark !important;
  }
}

.hover-menu:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.hover-menu-collapse {
  padding-left: 23px;
  transition: all 0.5s ease-out;
}

.active:hover .color-hover-active {
  color: $text-dark !important;
}

/*Diagonal menu*/

.nav {
  position: relative;

  > .nav-item {
    &:hover {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        z-index: 1;
        right: 0;
        width: 18%; // ** Tweak this **
        height: 120%;
      }
    }
  }
}

.sub-menu-aciso {
  &:hover {
    &:before {
      content: '';
      position: absolute;
      top: -20px;
      right: -20px;
      bottom: -20px;
      left: 0;
      z-index: -1;
      box-sizing: border-box;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #7d8083;
  border-radius: 30px;
}

::-webkit-scrollbar-track {
  background: #343a40;
  border-radius: 30px;
}

.icon-menu {
  align-items: center;
  margin: auto 12px auto 14px;
}

.text-menu {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.nav-sidebar .nav-item > .nav-link {
  margin-bottom: 0rem;
  cursor: pointer !important;
}

.nav-link.disabled {
  cursor: pointer !important;
  pointer-events: auto;
}

.nav-sidebar .nav-item {
  height: 34px;
}

.nav-sidebar {
  overflow-x: clip;
  overflow-y: clip;
}

.nav-header {
  padding-left: 0px !important;
  margin-left: 24px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: white !important;
}

[class*='sidebar-dark'] .brand-link {
  border-bottom: none !important;
  height: 65px !important;
  padding-bottom: 0px !important;
  margin-bottom: 32px !important;
}

.nav-link {
  padding-left: 0px !important;
}

.logo-aciso-menu {
  width: 195px;
}

.brand-link {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.disabled {
  color: #919191 !important;
}

.nav-main-sidebar {
  padding-bottom: 90px;
}

.badge-nb-days {
  width: 25px;
  height: 20px;
  color: white;
  font-weight: 700;
}

.nav-item {
  display: inline-block;
  vertical-align: middle;
}
</style>

<template>
  <!-- TODO configurable header title position -->
  <div :class="{ card: card_mode }">
    <div
      v-if="card_mode"
      class="card-header"
    >
      <slot name="card-header">
        <template>
          {{ card_title }}
        </template>
      </slot>
    </div>
    <div v-if="showToolsRow">
      <div :colspan="headerColSpan">
        <div class="row vbt-header-row no-gutters">
          <div class="col">
            <div class="row no-gutters">
              <!-- global search text starts here -->
              <div class="input-group vbt-global-search">
                <div
                  v-if="global_search.visibility"
                  class="form-group has-clear-right"
                  :class="global_search.class"
                >
                  <span v-if="query.global_search !== ''">
                    <span
                      v-if="global_search.showClearButton"
                      class="form-control-feedback vbt-global-search-clear"
                      @click="clearGlobalSearch"
                    >
                      <slot name="global-search-clear-icon">
                        <i class="far fa-times text-muted" />
                      </slot>
                    </span>
                  </span>

                  <input
                    v-if="global_search.searchOnPressEnter"
                    class="form-control g-search"
                    :placeholder="global_search.placeholder"
                    type="text"
                    @keyup.enter="updateGlobalSearchHandler($event.target.value)"
                  />
                  <input
                    v-else
                    ref="global_search"
                    class="form-control g-search"
                    data-testid="table-search-input"
                    :placeholder="global_search.placeholder"
                    type="text"
                    @keyup.stop="updateGlobalSearch($event.target.value)"
                  />
                </div>
                <div
                  v-for="(action, _key, index) in actions_select_left"
                  :class="action.class"
                >
                  <form-field-select
                    v-model="action.value"
                    allow_empty
                    class="ml-2"
                    :label="action.label"
                    :name="'select_' + index"
                    :options="action.options"
                    options_label="name"
                    :placeholder="action.placeholder"
                    trackby="id"
                    :value="action.value"
                    @change="$emit('left-selected', $event)"
                  />
                </div>
                <span
                  v-for="(action, _key, index) in actions_checkbox"
                  class="tenacy-quick-filter"
                >
                  <form-field-checkbox
                    v-model="action.value"
                    class="ml-2"
                    :label_secondary="action.btn_text"
                    :name="'checkbox_' + index"
                    :value="action.value"
                    @input="emitCheckboxEvent(action)"
                  />
                </span>
                <b-button-group class="float-left">
                  <span
                    v-for="(action, _key, index) in actions_btn"
                    v-b-tooltip.hover
                    :title="action.tooltip"
                  >
                    <b-button
                      v-if="action.funct === 'return'"
                      :key="index"
                      class="mr-1 mb-1 roundedButton"
                      :class="getActionButtonClass(action)"
                      :data-testid="action.btn_text"
                      :disabled="action.disabled"
                      :variant="'outline-' + (action.variant ? action.variant : 'primary')"
                      @click="emitActionEvent(action)"
                    >
                      {{ action.btn_text }}
                      <i
                        v-if="action.icon"
                        :class="'ml-1 far fa-' + action.icon"
                      />
                    </b-button>
                  </span>
                </b-button-group>
              </div>
            </div>
          </div>

          <!-- action buttons starts here -->
          <div class="col">
            <div v-for="(action, _key, index) in actions_select">
              <form-field-select
                v-model="action.value"
                allow_empty
                class="ml-2"
                :label="action.label"
                :name="'select_' + index"
                :options="action.options"
                options_label="name"
                :placeholder="action.placeholder"
                trackby="key"
                :value="action.value"
                @change="$emit('selected', $event)"
              />
            </div>
            <slot name="vbt-acton-buttons">
              <b-button-group class="float-right">
                <span
                  v-for="action in actions_toggle"
                  class="ml-3"
                >
                  <span class="mr-2 label-toggle">{{ action.btn_text }}</span>
                  <form-field-toggle
                    v-model="action.value"
                    class="mt-1 mr-3"
                    :value="action.value"
                    :width="40"
                    @input="emitToggleEvent(action)"
                  />
                </span>
                <span
                  v-for="(action, _key, index) in actions_btn"
                  v-b-tooltip.hover
                  :title="action.tooltip"
                >
                  <app-button
                    v-if="action.funct === 'add'"
                    :key="index"
                    :class="getActionButtonClass(action)"
                    :custom_icon="action.icon"
                    :custom_icon_after="action.custom_icon_after"
                    :data-testid="action.btn_text"
                    :disabled_condition="action.disabled"
                    :is_outlined="action.is_outlined"
                    :variant="'outline-' + (action.variant ? action.variant : 'primary')"
                    @click="emitActionEvent(action)"
                  >
                    {{ action.btn_text }}
                  </app-button>
                  <app-button-nav
                    v-if="action.funct === 'nav'"
                    :key="index"
                    :class="getActionButtonClass(action)"
                    :data-testid="action.btn_text"
                    :disabled_condition="action.disabled"
                    :variant="'outline-' + (action.variant ? action.variant : 'primary')"
                    @click="emitActionEvent(action)"
                  >
                    {{ action.btn_text }}
                  </app-button-nav>
                  <app-button-filter
                    v-if="action.funct === 'filter'"
                    :key="index"
                    :class="getActionButtonClass(action)"
                    :data-testid="action.btn_text"
                    :disabled="action.disabled"
                    :filter_active="action.filter_active"
                    :variant="'outline-' + (action.variant ? action.variant : 'primary')"
                    @click="emitActionEvent(action)"
                  />
                  <b-button
                    v-if="action.funct === 'secondary'"
                    :key="index"
                    class="mr-1 mb-1 roundedButton"
                    :class="getActionButtonClass(action)"
                    :data-testid="action.btn_text"
                    :disabled="action.disabled"
                    :variant="'outline-' + (action.variant ? action.variant : 'primary')"
                    @click="emitActionEvent(action)"
                  >
                    {{ action.btn_text }}
                    <i
                      v-if="action.icon"
                      :class="'ml-1 far fa-' + action.icon"
                    />
                  </b-button>
                  <app-button
                    v-if="action.funct === 'action'"
                    class="mr-1"
                    :custom_icon="''"
                    data-testid="btn-action-execute-connector"
                    :disabled="action.disabled"
                    @click="emitActionEvent(action)"
                  >
                    {{ action.btn_text }}
                  </app-button>
                  <b-button
                    v-if="action.funct === 'edit'"
                    :key="index"
                    class="mr-1 mb-1 roundedButton"
                    :class="getActionButtonClass(action)"
                    :data-testid="action.btn_text"
                    :disabled="action.disabled"
                    :variant="'outline-' + (action.variant ? action.variant : 'primary')"
                    @click="emitActionEvent(action)"
                  >
                    <i :class="'mr-1 far fa-pen'" />
                    {{ action.btn_text }}
                  </b-button>
                </span>
                <app-button-xls
                  v-if="actions_drop_xls && actions_drop_xls.length > 0"
                  class="mr-2"
                >
                  <b-dropdown-item
                    v-for="action in actions_drop_xls"
                    :key="action.id"
                    class="roundedButton"
                    :class="getActionButtonClass(action)"
                    :data-testid="action.btn_text"
                    :disabled="action.disabled"
                    @click="emitActionEvent(action)"
                  >
                    {{ action.btn_text }}
                  </b-dropdown-item>
                </app-button-xls>
                <b-dropdown
                  v-if="actions_drop && actions_drop.length > 0"
                  class="dropdown-tenacy"
                  data-testid="dropdown-button"
                  menu-class="roundedButton mr-1 mb-1"
                  no-caret
                  right
                  :text="''"
                  toggle-class="roundedButton mr-1"
                  :variant="'outline-primary'"
                >
                  <template #button-content><i class="far fa-ellipsis-v" /></template>
                  <b-dropdown-item
                    v-for="action in actions_drop"
                    :key="action.id"
                    class="roundedButton"
                    :class="getActionButtonClass(action)"
                    @click="emitActionEvent(action)"
                  >
                    {{ action.btn_text }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </slot>
          </div>
          <!-- action buttons button ends here -->
        </div>
      </div>
    </div>

    <div :class="{ 'card-body': card_mode }">
      <div
        class="vbt-table-wrapper"
        :class="tableWrapperClasses"
      >
        <table
          class="table"
          :class="tableClasses"
        >
          <thead>
            <tr v-if="!noHeader">
              <select-all-rows-check-box
                v-if="checkbox_rows && $store.getters.isRw && select_multiple"
                :all-rows-selected="allRowsSelected"
                :current-page-selection-count="currentPageSelectionCount"
                :rows_selected="selected_items.length"
                @select-all-row-checkbox="selectAllCheckbox"
              />
              <th
                v-else-if="checkbox_rows && $store.getters.isRw"
                class="text-center justify-content-center"
              >
                &nbsp;
              </th>
              <slot
                :columns="vbt_columns"
                name="columns"
              >
                <template v-for="(column, key, index) in vbt_columns">
                  <th
                    v-if="canShowColumn(column)"
                    :key="index"
                    class="vbt-column-header"
                    :class="columnClasses(column)"
                    :data-testid="`column-header-${column.name}`"
                    v-on="isSortableColumn(column) ? { click: () => updateSortQuery(column) } : {}"
                  >
                    <div
                      v-if="key === 0 && selected_items.length > 0"
                      :class="
                        select_multiple && delete_icon
                          ? 'aciso-toolbox'
                          : !select_multiple && delete_icon
                          ? 'aciso-toolbox-single'
                          : ' '
                      "
                    >
                      <slot name="multi_edit" />
                    </div>

                    <slot
                      :column="column"
                      :name="'column_' + getCellSlotName(column)"
                    >
                      <template v-if="key > 0 || selected_items.length === 0">
                        {{ column.label }}
                      </template>
                      <template v-else>&nbsp;</template>
                    </slot>

                    <template v-if="isSortableColumn(column) && (key > 0 || selected_items.length === 0)">
                      <SortIcon
                        :column="column"
                        :sort="query.sort"
                      >
                        <template slot="vbt-sort-asc-icon">
                          <slot name="sort-asc-icon"> &#x1F825;</slot>
                        </template>
                        <template slot="vbt-sort-desc-icon">
                          <slot name="sort-desc-icon"> &#x1F827;</slot>
                        </template>
                        <template slot="vbt-no-sort-icon">
                          <slot name="no-sort-icon"> &#x1F825;&#x1F827;</slot>
                        </template>
                      </SortIcon>
                    </template>
                  </th>
                </template>
              </slot>
            </tr>
          </thead>
          <tbody data-testid="table-rows">
            <!-- filter row starts here -->
            <tr
              v-if="showFilterRow"
              class="table-active"
            >
              <td v-show="checkbox_rows && $store.getters.isRw" />
              <template v-for="(column, _key, index) in vbt_columns">
                <td
                  v-if="canShowColumn(column)"
                  :key="index"
                  align="center"
                >
                  <template v-if="hasFilter(column)">
                    <Simple
                      v-if="column.filter.type == 'simple'"
                      :column="column"
                      @clear-filter="clearFilter"
                      @update-filter="updateFilter"
                    >
                      <template slot="vbt-simple-filter-clear-icon">
                        <slot name="simple-filter-clear-icon"> &#x24E7;</slot>
                      </template>
                    </Simple>
                    <MultiSelect
                      v-if="column.filter.type == 'select'"
                      :column="column"
                      :options="column.filter.options"
                      @clear-filter="clearFilter"
                      @update-multi-select-filter="updateMultiSelectFilter"
                    />

                    <MultiSelect
                      v-if="column.filter.type == 'aciso_custom' || column.filter.type == 'custom_bool'"
                      :column="column"
                      :options="column.filter.options"
                      @clear-filter="clearFilter"
                      @update-multi-select-filter="updateMultiSelectFilter"
                    />

                    <template v-if="column.filter.type == 'custom'">
                      <slot
                        :column="column"
                        :name="column.filter.slot_name"
                      />
                    </template>
                  </template>
                </td>
              </template>
            </tr>
            <!-- filter row ends here -->

            <!-- data rows stars here -->
            <template v-if="enableDrag">
              <draggable
                v-bind="dragOptions"
                v-model="ordered_items"
                class="list-group"
                handle=".handle"
                style="display: contents"
                tag="li"
                @end="showActionBar(true)"
                @start="showActionBar(false)"
              >
                <row
                  v-for="(row, index) in vbt_rows"
                  :key="index"
                  :ref="row.id ? 'row_' + row.id : null"
                  :checkbox-rows="checkbox_rows && $store.getters.isRw"
                  :class="{ selected_row: row_selected && row_selected.id === row.id }"
                  :columns="vbt_columns"
                  :highlight-row-hover="highlight_row_hover"
                  :highlight-row-hover-color="rowHighlightColor"
                  :mapping-ellipsis="mappingEllipsis"
                  :no-ellipsis="no_ellipsis"
                  :prop-cell-classes="classes.cell"
                  :prop-row-classes="classes.row"
                  :radio_button="radio_button"
                  :row="row"
                  :row-index="index"
                  :rows-selectable="rows_selectable"
                  :selected-items="selected_items"
                  :show_actions="show_actions"
                  :small-table="smallTable"
                  :style="{ cursor: clickable ? '' : 'auto !important' }"
                  :unique-id="uniqueId"
                  @add-row="handleAddRow"
                  @clicked="selectRow"
                  @remove-row="handleRemoveRow"
                  @row_hover="$emit('row_hover', $event)"
                  @row_hover_out="$emit('row_hover_out')"
                  @row_out="$emit('row_out', $event)"
                  @row_over="$emit('row_over', $event)"
                >
                  <template
                    v-for="column in columns"
                    :slot="'vbt-' + getCellSlotName(column)"
                  >
                    <slot
                      :cell_value="getValueFromRow(row, column.name)"
                      :column="column"
                      :name="getCellSlotName(column)"
                      :row="row"
                    >
                      {{ getValueFromRow(row, column.name) }}
                    </slot>
                  </template>

                  <template slot="vbt-actions_">
                    <slot
                      cell_value=""
                      name="actions_"
                      :row="row"
                    >
                      &nbsp;
                    </slot>
                  </template>
                </row>
              </draggable>
            </template>
            <template v-else>
              <row
                v-for="(row, index) in vbt_rows"
                :key="index"
                :ref="row.id ? 'row_' + row.id : null"
                :checkbox-rows="checkbox_rows && $store.getters.isRw"
                :class="{
                  selected_row: row_selected && row_selected.id === row.id,
                  linked_row: row.hasOwnProperty('linked') && row.linked,
                }"
                :columns="vbt_columns"
                :highlight-row-hover="highlight_row_hover"
                :highlight-row-hover-color="rowHighlightColor"
                :mapping-ellipsis="mappingEllipsis"
                :no-ellipsis="no_ellipsis"
                :prop-cell-classes="classes.cell"
                :prop-row-classes="classes.row"
                :radio_button="radio_button"
                :row="row"
                :row-index="index"
                :rows-selectable="rows_selectable"
                :selected-items="selected_items"
                :show_actions="show_actions"
                :small-table="smallTable"
                :style="{ cursor: clickable ? '' : 'auto !important' }"
                :unique-id="uniqueId"
                @add-row="handleAddRow"
                @clicked="selectRow(row)"
                @remove-row="handleRemoveRow"
                @row_out="$emit('row_out', $event)"
                @row_over="$emit('row_over', $event)"
              >
                <template
                  v-for="column in columns"
                  :slot="'vbt-' + getCellSlotName(column)"
                >
                  <slot
                    :cell_value="getValueFromRow(row, column.name)"
                    :column="column"
                    :name="getCellSlotName(column)"
                    :row="row"
                  >
                    {{ getValueFromRow(row, column.name) }}
                  </slot>
                </template>

                <template slot="vbt-actions_">
                  <slot
                    cell_value=""
                    name="actions_"
                    :row="row"
                  >
                    &nbsp;
                  </slot>
                </template>
              </row>
            </template>

            <!-- empty row starts here -->
            <tr v-show="vbt_rows == 0">
              <td :colspan="headerColSpan">
                <slot name="empty-results" />
              </td>
            </tr>
            <!-- empty row ends here -->

            <!-- data rows ends here -->

            <!-- Pagination row starts here -->
            <tr
              v-if="showPaginationRow"
              class="footer-pagination-row"
            >
              <td :colspan="headerColSpan">
                <div class="row vbt-pagination-row no-gutters">
                  <!-- pagination starts here -->
                  <div class="col-md-8">
                    <div v-if="pagination && rowCount > per_page">
                      <Pagination
                        :num_of_visibile_pagination_buttons="num_of_visibile_pagination_buttons"
                        :page.sync="page"
                        :per_page.sync="per_page"
                        :per_page_options="per_page_options"
                        :total="rowCount"
                      >
                        <template slot="vbt-paginataion-previous-button">
                          <slot name="paginataion-previous-button"> &laquo;</slot>
                        </template>
                        <template slot="vbt-paginataion-next-button">
                          <slot name="paginataion-next-button"> &raquo;</slot>
                        </template>
                      </Pagination>
                    </div>
                  </div>
                  <!-- pagination ends here -->

                  <!-- pagination info start here -->
                  <div class="col-md-4">
                    <div class="text-right justify-content-center">
                      <template v-if="pagination_info">
                        <slot
                          :current-page-rows-length="currentPageRowsLength"
                          :filtered-rows-length="filteredRowsLength"
                          name="pagination-info"
                          :original-rows-length="originalRowsLength"
                        >
                          <template v-if="currentPageRowsLength != 0">
                            {{ computePaginationInfo }}
                          </template>
                          <template v-else>
                            <translate>No data.</translate>
                          </template>
                          <template> ({{ originalRowsLength }} {{ _('total records') }})</template>
                        </slot>
                      </template>
                      <template v-if="selected_rows_info && pagination_info && isSelectable">
                        <slot name="pagination-selected-rows-separator"> |</slot>
                      </template>
                      <template v-if="selected_rows_info && isSelectable">
                        <slot
                          name="selected-rows-info"
                          :selected-items-count="selectedItemsCount"
                        >
                          {{ selectedItemsCount }} {{ _('rows selected') }}
                        </slot>
                      </template>
                    </div>
                  </div>
                  <!-- pagination info ends here -->
                </div>
              </td>
            </tr>
            <!-- Pagination ends starts here -->
          </tbody>
        </table>
        <slot name="spin-loading" />
      </div>
    </div>
    <div
      v-if="card_mode"
      class="card-footer"
    >
      <slot name="card-footer">
        <div class="row">
          <!-- pagination starts here -->
          <div class="col-md-6">
            <div v-if="pagination">
              <Pagination
                :num_of_visibile_pagination_buttons="num_of_visibile_pagination_buttons"
                :page.sync="page"
                :per_page.sync="per_page"
                :per_page_options="per_page_options"
                :total="rowCount"
              >
                <template slot="vbt-paginataion-previous-button">
                  <slot name="paginataion-previous-button"> &laquo;</slot>
                </template>
                <template slot="vbt-paginataion-next-button">
                  <slot name="paginataion-next-button"> &raquo;</slot>
                </template>
              </Pagination>
            </div>
          </div>
          <!-- pagination ends here -->

          <!-- pagination info start here -->
          <div class="col-md-6">
            <div class="text-right justify-content-center">
              <template v-if="pagination_info">
                <slot
                  :current-page-rows-length="currentPageRowsLength"
                  :filtered-rows-length="filteredRowsLength"
                  name="pagination-info"
                  :original-rows-length="originalRowsLength"
                >
                  <template v-if="currentPageRowsLength != 0">
                    {{ computePaginationInfo }}
                  </template>
                  <template v-else>
                    <translate>No data.</translate>
                  </template>
                  <template> ({{ originalRowsLength }} {{ _('total records') }})</template>
                </slot>
              </template>
              <template v-if="pagination_info && selected_rows_info">
                <slot name="pagination-selected-rows-separator"> |</slot>
              </template>
              <template v-if="selected_rows_info">
                <slot
                  name="selected-rows-info"
                  :selected-items-count="selectedItemsCount"
                >
                  {{ selectedItemsCount }} rows selected
                </slot>
              </template>
            </div>
          </div>
          <!-- pagination info ends here -->
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import findIndex from 'lodash/findIndex'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import has from 'lodash/has'
import extend from 'lodash/extend'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import differenceWith from 'lodash/differenceWith'
import differenceBy from 'lodash/differenceBy'
import intersectionWith from 'lodash/intersectionWith'
import intersectionBy from 'lodash/intersectionBy'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import omit from 'lodash/omit'
import clone from 'lodash/clone'

import Row from './Row.vue'
import SelectAllRowsCheckBox from './SelectAllRowsCheckBox.vue'
import SortIcon from './SortIcon.vue'
import Pagination from './Pagination.vue'
import Simple from './Filters/Simple.vue'
import MultiSelect from './Filters/MultiSelect.vue'
import Draggable from 'vuedraggable'
import FormFieldToggle from '@/components/ui/form-field-toggle.vue'
import AppButton from '@/components/ui/app-button.vue'
import AppButtonXls from '@/components/ui/app-button-xls.vue'
import AppButtonFilter from '@/components/ui/app-button-filter.vue'
import AppButtonNav from '@/components/ui/app-button-nav.vue'
import FormFieldCheckbox from '@/components/ui/form-field-checkbox.vue'

import { EventBus } from '../event-bus.js'
import FormFieldSelect from '@/components/ui/form-field-select.vue'
import { sortOrder } from '@/helpers/Sorting.helpers'

export default {
  name: 'VueBootstrap4Table',
  components: {
    FormFieldSelect,
    Row,
    SelectAllRowsCheckBox,
    Simple,
    MultiSelect,
    SortIcon,
    Pagination,
    Draggable,
    FormFieldToggle,
    AppButton,
    AppButtonXls,
    AppButtonFilter,
    AppButtonNav,
    FormFieldCheckbox,
  },
  props: {
    enableDrag: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    smallTable: {
      type: Boolean,
      default: false,
    },
    mappingEllipsis: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default: function () {
        return {}
      },
    },
    classes: {
      type: Object,
      default: function () {
        return {}
      },
    },
    actions: {
      type: Array,
      default: function () {
        return []
      },
    },
    customFilters: {
      type: Array,
      default: function () {
        return []
      },
    },
    'no-header': {
      type: Boolean,
      default: false,
    },
    delete_icon: {
      type: Boolean,
      default: false,
    },
    filterInMounted: {
      type: Boolean,
      default: true,
    },
    sticky_: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      select_multiple: true,
      show_actions: true,
      no_ellipsis: false,
      vbt_rows: [],
      vbt_columns: [],
      query: {
        sort: [],
        filters: [],
        global_search: '',
      },
      page: 1,
      per_page: 20,
      original_rows: [],
      num_of_visibile_pagination_buttons: 5,
      temp_filtered_results: [],
      pagination: true,
      pagination_info: true,
      checkbox_rows: false,
      radio_button: false,
      selected_items: [],
      row_selected: null,
      highlight_row_hover: true,
      highlight_row_hover_color: '#F7F8F7',
      rows_selectable: false,
      allRowsSelected: false,
      multi_column_sort: false,
      card_title: '',
      global_search: {
        placeholder: 'Enter search text',
        class: '',
        visibility: true,
        case_sensitive: false,
        showClearButton: true,
        searchOnPressEnter: false,
        searchDebounceRate: 6000,
        init: {
          value: '',
        },
      },
      per_page_options: [20],
      show_refresh_button: false,
      show_reset_button: true,
      server_mode: false,
      total_rows: 0,
      card_mode: true,
      selected_rows_info: false,
      lastSelectedItemIndex: null,
      isFirstTime: true,
      isResponsive: true,
      preservePageOnDataChange: false,
      canEmitQueries: false,
    }
  },
  computed: {
    computePaginationInfo() {
      return (
        this._('From 1 to') +
        ' ' +
        this.currentPageRowsLength +
        ' ' +
        this._('of') +
        ' ' +
        this.filteredRowsLength +
        ' ' +
        this._('entries')
      )
    },
    ordered_items: {
      get: function () {
        if ('rows' in this) {
          return this.rows.slice().sort(sortOrder)
        } else {
          return []
        }
      },
      set: function (newVal) {
        this.$emit('reorder', newVal)
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    rowCount() {
      if (!this.server_mode) {
        return this.temp_filtered_results.length
      } else {
        return this.totalRows
      }
    },
    actions_btn() {
      if (this.actions) {
        return this.actions.filter((e) => !e.type || (e.type !== 'dropdown' && e.type !== 'toggle'))
      }
      return []
    },
    actions_toggle() {
      if (this.actions) {
        return this.actions.filter((e) => e.type && e.type === 'toggle')
      }
      return []
    },
    actions_checkbox() {
      if (this.actions) {
        return this.actions.filter((e) => e.type && e.type === 'checkbox')
      }
      return []
    },
    actions_select() {
      if (this.actions) {
        return this.actions.filter((e) => e.type && e.type === 'select')
      }
      return []
    },
    actions_select_left() {
      if (this.actions) {
        return this.actions.filter((e) => e.type && e.type === 'select-left')
      }
      return []
    },
    actions_drop() {
      if (this.actions) {
        return this.actions.filter((e) => e.type && e.type === 'dropdown')
      }
      return []
    },
    actions_drop_xls() {
      if (this.actions) {
        return this.actions.filter((e) => e.type && e.type === 'dropdown-xls')
      }
      return []
    },
    selectedItemsCount() {
      return this.selected_items.length
    },
    filteredResultsCount() {
      return this.temp_filtered_results.length
    },
    uniqueId() {
      let unique_id = ''

      if (!this.hasUniqueId) {
        unique_id = 'vbt_id'
        return unique_id
      }
      this.vbt_columns.some((column) => {
        if (has(column, 'uniqueId') && column.uniqueId === true) {
          unique_id = column.name
          return true
        }
      })

      return unique_id
    },
    hasUniqueId() {
      let has_unique_id = false

      this.vbt_columns.some((column) => {
        if (has(column, 'uniqueId') && column.uniqueId === true) {
          has_unique_id = true
          return true
        }
      })

      return has_unique_id
    },

    // pagination info computed properties - start

    currentPageRowsLength() {
      return this.vbt_rows.length
    },

    filteredRowsLength() {
      return this.rowCount
    },

    originalRowsLength() {
      return this.server_mode ? this.rowCount : this.rows.length
    },

    // pagination info computed properties - end
    rowHighlightColor() {
      return this.highlight_row_hover ? this.highlight_row_hover_color : ''
    },

    headerColSpan() {
      let count = this.checkbox_rows ? 1 : 0

      count += this.vbt_columns.filter((column) => this.canShowColumn(column)).length

      return count
    },

    showToolsRow() {
      return (
        this.global_search.visibility == true ||
        this.show_refresh_button == true ||
        this.show_reset_button == true ||
        this.actions.length > 0
      )
    },

    showFilterRow() {
      let show_row = false

      this.columns.some((column) => {
        if (has(column, 'filter')) {
          show_row = true
          return true
        }
      })
      return show_row
    },

    showPaginationRow() {
      let show_pagination_row = false

      if (
        this.card_mode == false &&
        (this.pagination == true || this.pagination_info == true || this.selected_rows_info == true)
      ) {
        show_pagination_row = true
      }

      return show_pagination_row
    },

    currentPageSelectionCount() {
      let result = []
      if (this.server_mode && !this.hasUniqueId) {
        result = intersectionWith(this.vbt_rows, this.selected_items, isEqual)
      } else {
        result = intersectionBy(this.vbt_rows, this.selected_items, this.uniqueId)
      }
      return result.length
    },
    tableClasses() {
      let classes = ''
      if (typeof this.classes.table == 'string') {
        return this.classes.table
      } else if (typeof this.classes.table == 'object') {
        Object.entries(this.classes.table).forEach(([key, value]) => {
          if (typeof value == 'boolean' && value) {
            classes += key
          } else if (typeof value == 'function') {
            const truth = value(this.rows)
            if (typeof truth == 'boolean' && truth) {
              classes += ' '
              classes += key
            }
          }
        })
      }
      return classes
    },
    tableWrapperClasses() {
      let defaultClasses = 'table-responsive-one'

      if (this.rows.length > 1) {
        defaultClasses = 'table-responsive'
      }

      if (!this.classes.tableWrapper && this.classes.tableWrapper != '') {
        return defaultClasses
      }

      return typeof this.classes.tableWrapper == 'string' ? this.classes.tableWrapper : defaultClasses
    },

    isSelectable() {
      return this.checkbox_rows || this.rows_selectable
    },

    updateGlobalSearch() {
      return debounce(this.updateGlobalSearchHandler, this.global_search.searchDebounceRate)
    },
  },
  watch: {
    'query.filters': {
      handler: function () {
        if (!this.server_mode) {
          this.filter(!this.preservePageOnDataChange)
        }
      },
      deep: true,
    },
    'query.sort': {
      handler: function () {
        if (!this.server_mode) {
          this.sort()
        }
      },
      deep: true,
    },
    'query.global_search': {
      handler: function () {
        if (!this.server_mode) {
          this.filter(!this.preservePageOnDataChange)
        }
      },
    },
    query: {
      handler: function () {
        if (this.server_mode) {
          this.emitQueryParams()
        }
      },
      deep: true,
    },
    per_page: {
      handler: function (newVal) {
        if (!this.server_mode) {
          const doPaginateFilter = this.page == 1
          if (!this.preservePageOnDataChange) {
            this.page = 1
          }
          if (doPaginateFilter) {
            this.paginateFilter()
          }
        } else {
          this.emitQueryParams()
        }
        this.$emit('per_page_update', newVal)
      },
    },
    pagination: {
      handler: function () {
        if (!this.server_mode) {
          this.paginateFilter()
        } else {
          this.emitQueryParams()
        }
      },
    },
    rows: {
      handler: function () {
        this.vbt_rows = cloneDeep(this.rows)
        if (this.vbt_rows.some((e) => e.delete_right === true || e.checkbox_right === true)) {
          this.checkbox_rows = has(this.config, 'checkbox_rows') ? this.config.checkbox_rows : false
        } else {
          this.checkbox_rows = false
        }
        this.unSelectAllItems()
        if (!this.server_mode) {
          // check if user mentioned unique id for a column, if not set unique id for all items
          const _this = this
          this.original_rows = map(this.vbt_rows, function (element, index) {
            const extra = {}
            if (!_this.hasUniqueId) {
              extra.vbt_id = index + 1
            }
            return extend({}, element, extra)
          })
          this.filter(!this.preservePageOnDataChange, !this.isFirstTime)
        } else {
          if (this.preservePageOnDataChange) {
            const predictedTotalPage = Math.ceil(this.rowCount / this.per_page)
            if (predictedTotalPage != 0) {
              this.page = this.page <= predictedTotalPage ? this.page : predictedTotalPage
            } else {
              this.page = 1
            }
          }
        }

        this.isFirstTime = false
      },
      deep: true,
    },
    customFilters: {
      handler: function (newVal) {
        if (!this.server_mode) {
          newVal.forEach((customFilter) => {
            if (customFilter.name) {
              if (this.query.filters) {
                const index = this.query.filters.findIndex((filter) => filter.name === customFilter.name)
                if (index == -1) {
                  this.query.filters.push(customFilter)
                } else {
                  this.query.filters[index].text = customFilter.text
                }
              }
            }
          })
        }
      },
      deep: true,
    },
    columns: {
      handler: function () {
        this.vbt_columns = cloneDeep(this.columns)

        this.vbt_columns = map(this.vbt_columns, function (element, index) {
          const extra = {}
          extra.vbt_col_id = index + 1
          return extend({}, element, extra)
        })

        this.initFilterQueries()
      },
      deep: true,
    },
    config: {
      handler: function () {
        this.initConfig()
      },
      deep: true,
    },

    vbt_rows: {
      handler: function (newVal) {
        // resetting the shift mode
        this.lastSelectedItemIndex = null

        if (this.selected_items.length == 0) {
          // EventBus.$emit('unselect-select-all-items-checkbox');
          this.allRowsSelected = false
          return
        }

        let difference

        if (this.server_mode && !this.hasUniqueId) {
          difference = differenceWith(newVal, this.selected_items, isEqual)
        } else {
          difference = differenceBy(newVal, this.selected_items, this.uniqueId)
        }

        if (difference.length == 0) {
          // EventBus.$emit('select-select-all-items-checkbox');
          this.allRowsSelected = true
        } else {
          this.allRowsSelected = false
          // EventBus.$emit('unselect-select-all-items-checkbox');
        }
      },
      deep: true,
    },

    page(newVal) {
      if (!this.server_mode) {
        this.paginateFilter()
      } else {
        this.emitQueryParams(newVal)
      }
    },
    'config.multi_column_sort': {
      handler: function () {
        this.resetSort()
      },
    },
  },
  mounted() {
    this.vbt_rows = cloneDeep(this.rows)
    this.vbt_columns = cloneDeep(this.columns)
    const _this = this
    // check if user mentioned unique id for a column, if not set unique id for all items
    this.original_rows = map(this.vbt_rows, function (element, index) {
      const extra = {}
      if (!_this.hasUniqueId) {
        extra.vbt_id = index + 1
      }
      return extend({}, element, extra)
    })

    this.vbt_columns = map(this.vbt_columns, function (element, index) {
      const extra = {}
      extra.vbt_col_id = index + 1
      return extend({}, element, extra)
    })

    this.initConfig()
    this.initialSort()
    this.initFilterQueries()

    if (this.global_search.visibility) {
      this.$nextTick(() => {
        this.initGlobalSearch()
      })
    }

    this.$nextTick(() => {
      if (!this.server_mode) {
        this.filter(false, true)
      } else {
        this.canEmitQueries = true
        if (this.filterInMounted) {
          this.emitQueryParams()
        }
      }
    })

    this.handleShiftKey()
  },
  methods: {
    selectRow(event) {
      this.$emit('clicked', event)
      if (this.rows_selectable) {
        this.row_selected = event
        this.$set(event, 'linked', true)
      }
    },
    setSelectedRow(row) {
      this.row_selected = row
    },
    initConfig() {
      if (isEmpty(this.config)) {
        return
      }

      this.pagination = has(this.config, 'pagination') ? this.config.pagination : true

      this.num_of_visibile_pagination_buttons = has(this.config, 'num_of_visibile_pagination_buttons')
        ? this.config.num_of_visibile_pagination_buttons
        : 7

      this.per_page_options = has(this.config, 'per_page_options') ? this.config.per_page_options : [20]

      this.per_page = has(this.config, 'per_page') ? this.config.per_page : 10

      this.page = has(this.config, 'page') ? this.config.page : 1

      this.radio_button = has(this.config, 'radio_button') ? this.config.radio_button : false

      if (this.vbt_rows.some((e) => e.delete_right === true || e.checkbox_right === true)) {
        this.checkbox_rows = has(this.config, 'checkbox_rows') ? this.config.checkbox_rows : false
      } else {
        this.checkbox_rows = false
      }

      this.highlight_row_hover = has(this.config, 'highlight_row_hover') ? this.config.highlight_row_hover : true

      this.highlight_row_hover_color = has(this.config, 'highlight_row_hover_color')
        ? this.config.highlight_row_hover_color
        : '#F7F8F7'

      this.rows_selectable = has(this.config, 'rows_selectable') ? this.config.rows_selectable : false

      this.multi_column_sort = has(this.config, 'multi_column_sort') ? this.config.multi_column_sort : false

      this.pagination_info = has(this.config, 'pagination_info') ? this.config.pagination_info : true

      this.card_title = has(this.config, 'card_title') ? this.config.card_title : ''
      this.select_multiple = has(this.config, 'select_multiple') ? this.config.select_multiple : true
      this.show_actions = has(this.config, 'show_actions') ? this.config.show_actions : true
      this.no_ellipsis = has(this.config, 'no_ellipsis') ? this.config.no_ellipsis : false

      this.server_mode = has(this.config, 'server_mode') ? this.config.server_mode : false

      if (has(this.config, 'global_search')) {
        this.global_search.placeholder = has(this.config.global_search, 'placeholder')
          ? this.config.global_search.placeholder
          : 'Enter search text'
        this.global_search.visibility = has(this.config.global_search, 'visibility')
          ? this.config.global_search.visibility
          : true
        this.global_search.case_sensitive = has(this.config.global_search, 'case_sensitive')
          ? this.config.global_search.case_sensitive
          : false
        this.global_search.showClearButton = has(this.config.global_search, 'showClearButton')
          ? this.config.global_search.showClearButton
          : true
        this.global_search.searchOnPressEnter = has(this.config.global_search, 'searchOnPressEnter')
          ? this.config.global_search.searchOnPressEnter
          : false
        this.global_search.searchDebounceRate = has(this.config.global_search, 'searchDebounceRate')
          ? this.config.global_search.searchDebounceRate
          : this.server_mode
          ? 600
          : 60
        this.global_search.class = has(this.config.global_search, 'class') ? this.config.global_search.class : ''
        this.global_search.fields = has(this.config.global_search, 'fields') ? this.config.global_search.fields : []
        this.global_search.init.value = has(this.config.global_search, 'init.value')
          ? this.config.global_search.init.value
          : ''
        $('.g-search:first').val(this.global_search.init.value)
      }

      this.show_refresh_button = has(this.config, 'show_refresh_button') ? this.config.show_refresh_button : true

      this.show_reset_button = has(this.config, 'show_reset_button') ? this.config.show_reset_button : true

      this.card_mode = has(this.config, 'card_mode') ? this.config.card_mode : true

      this.selected_rows_info = has(this.config, 'card_mode') ? this.config.selected_rows_info : false

      this.preservePageOnDataChange = has(this.config, 'preservePageOnDataChange')
        ? this.config.preservePageOnDataChange
        : true
    },

    initialSort() {
      // TODO optimze this with removing this filter
      const initial_sort_columns = filter(
        this.vbt_columns,
        (column) => has(column, 'initial_sort') && column.initial_sort == true
      )

      initial_sort_columns.some((initial_sort_column) => {
        const result = findIndex(this.query.sort, {
          vbt_col_id: initial_sort_column.vbt_col_id,
        })

        if (result == -1) {
          // initial sort order validation starts here
          let initial_sort_order = 'asc'
          if (has(initial_sort_column, 'initial_sort_order')) {
            if (includes(['asc', 'desc'], initial_sort_column.initial_sort_order)) {
              initial_sort_order = initial_sort_column.initial_sort_order
            }
          }
          // initial sort order validation ends here
          this.query.sort.push({
            vbt_col_id: initial_sort_column.vbt_col_id,
            name: initial_sort_column.name,
            cb: initial_sort_column.sort_cb,
            is_number: this.isSortNumber(initial_sort_column),
            order: initial_sort_order,
            caseSensitive: this.isSortCaseSensitive(initial_sort_column),
          })
        }

        // if multicolum sort sort is false, then consider only first initial sort column
        if (!this.multi_column_sort) {
          return true
        }
      })
    },

    initGlobalSearch() {
      this.$refs.global_search.value = this.global_search.init.value
      this.query.global_search = this.global_search.init.value
    },

    hasFilter(column) {
      return has(column, 'filter.type')
    },

    clearFilter(column) {
      const filter_index = this.getFilterIndex(column)
      if (filter_index !== -1) {
        this.query.filters.splice(filter_index, 1)
      }
    },

    getFilterIndex(column) {
      return findIndex(this.query.filters, {
        name: column.name,
      })
    },

    initFilterQueries() {
      this.vbt_columns.forEach((vbt_column) => {
        if (!has(vbt_column, 'filter')) {
          return
        }

        if (vbt_column.filter.type == 'simple') {
          if (!has(vbt_column, 'filter.init.value')) {
            return
          }

          this.updateFilter({
            value: vbt_column.filter.init.value,
            column: vbt_column,
          })
        } else if (vbt_column.filter.type == 'select') {
          if (!has(vbt_column, 'filter.init.value')) {
            return
          }

          let initialValues = []
          if (vbt_column.filter.mode == 'multi') {
            if (Array.isArray(vbt_column.filter.init.value)) {
              initialValues = vbt_column.filter.init.value
            } else {
              this.$log.debug("Initial value for 'multi' mode should be an array")
            }
          } else if (vbt_column.filter.mode == 'single') {
            if (Number.isInteger(vbt_column.filter.init.value) && vbt_column.filter.init.value > -1) {
              initialValues = [vbt_column.filter.init.value]
            } else {
              this.$log.debug("Initial value for 'single' mode should be a single number and greater than -1")
            }
          }

          const selected_options = vbt_column.filter.options
            .filter((_, index) => includes(initialValues, index))
            .map((filtered_option) => filtered_option.value)

          this.updateMultiSelectFilter({
            selected_options: selected_options,
            column: vbt_column,
          })
        } else if (vbt_column.filter.type === 'custom_bool') {
          if (vbt_column.filter.mode === 'aciso_bool') {
            if (this.$route.params.warning === 'true' || this.$route.query.warning === 'true') {
              const selected_options = [true]

              this.updateMultiSelectFilter({
                selected_options: selected_options,
                column: vbt_column,
              })
            }
          }
        } else if (vbt_column.filter.type === 'aciso_custom') {
          if (this.$route.params.idcamp) {
            if (vbt_column.filter.options.find((e) => e.id === +this.$route.params.idcamp)) {
              let selected_options = vbt_column.filter.options.find((e) => e.id === +this.$route.params.idcamp)
              selected_options = [selected_options.id]
              this.updateMultiSelectFilter({
                selected_options: selected_options,
                column: vbt_column,
              })
            }
          }
        }
      })
    },

    isSortCaseSensitive(column) {
      return column.sortCaseSensitive != undefined ? column.sortCaseSensitive : true
    },
    isSortNumber(column) {
      return column.is_number != undefined ? column.is_number : false
    },

    updateSortQuery(column) {
      const result = findIndex(this.query.sort, { vbt_col_id: column.vbt_col_id })
      if (result == -1) {
        if (!this.multi_column_sort) {
          this.query.sort = []
        }
        this.query.sort.push({
          vbt_col_id: column.vbt_col_id,
          name: column.name,
          cb: column.sort_cb,
          is_number: this.isSortNumber(column),
          order: 'asc',
          caseSensitive: this.isSortCaseSensitive(column),
        })
      } else {
        this.query.sort[result].order = this.query.sort[result].order == 'asc' ? 'desc' : 'asc'
      }
    },
    isShiftSelection(shiftKey, rowIndex) {
      return shiftKey == true && this.lastSelectedItemIndex != null && this.lastSelectedItemIndex != rowIndex
    },
    handleAddRow(payload) {
      const row = this.vbt_rows[payload.rowIndex]
      if (this.isShiftSelection(payload.shiftKey, payload.rowIndex)) {
        const rows = this.getShiftSelectionRows(payload.rowIndex)
        rows.forEach((_row) => {
          this.addSelectedItem(_row)
        })
      } else {
        if (this.select_multiple === false) {
          this.unSelectAllItems()
        }
        this.$nextTick(() => {
          this.addSelectedItem(row)
          this.$log.debug(this.selected_items)
          this.$emit('on-select-row', {
            selected_items: cloneDeep(this.selected_items),
            selected_item: row,
          })

          let difference

          if (this.server_mode && !this.hasUniqueId) {
            difference = differenceWith(this.vbt_rows, this.selected_items, isEqual)
          } else {
            difference = differenceBy(this.vbt_rows, this.selected_items, this.uniqueId)
          }
          if (difference.length == 0) {
            this.allRowsSelected = true
            // EventBus.$emit('select-select-all-items-checkbox', "from main");
          } else {
            this.allRowsSelected = false
            // EventBus.$emit('unselect-select-all-items-checkbox', "from main");
          }

          this.lastSelectedItemIndex = payload.rowIndex
        })
      }
    },

    getActionButtonClass(action) {
      return has(action, 'class') ? action.class : ''
    },

    handleRemoveRow(payload) {
      const row = this.vbt_rows[payload.rowIndex]
      if (this.isShiftSelection(payload.shiftKey, payload.rowIndex)) {
        const rows = this.getShiftSelectionRows(payload.rowIndex)
        rows.forEach((_row) => {
          this.removeSelectedItem(_row)
        })
      } else {
        this.removeSelectedItem(row)
      }
      this.$emit('on-unselect-row', {
        selected_items: cloneDeep(this.selected_items),
        unselected_item: row,
      })
      // EventBus.$emit('unselect-select-all-items-checkbox');
      this.allRowsSelected = false
      this.lastSelectedItemIndex = payload.rowIndex
    },
    addSelectedItem(item) {
      let index = -1
      if (this.server_mode && !this.hasUniqueId) {
        index = findIndex(this.selected_items, (selected_item) => {
          return isEqual(selected_item, item)
        })
      } else {
        index = findIndex(this.selected_items, (selected_item) => {
          return selected_item[this.uniqueId] == item[this.uniqueId]
        })
      }

      if (index == -1) {
        if (item.delete_right || item.checkbox_right === true) {
          this.selected_items.push(item)
        }
      }
    },
    selectAllItems() {
      let difference = []

      if (this.server_mode && !this.hasUniqueId) {
        difference = differenceWith(
          this.vbt_rows.filter((e) => e.delete_right === true || e.checkbox_right === true),
          this.selected_items,
          isEqual
        )
      } else {
        difference = differenceBy(
          this.vbt_rows.filter((e) => e.delete_right === true || e.checkbox_right === true),
          this.selected_items,
          this.uniqueId
        )
      }

      this.selected_items.push(...difference)
      this.$emit('on-all-select-rows', {
        selected_items: cloneDeep(this.selected_items),
      })
    },
    unSelectAllItems() {
      this.selected_items = []

      this.$emit('on-all-unselect-rows', {
        selected_items: cloneDeep(this.selected_items),
      })
    },
    removeSelectedItem(item) {
      // TODO try with findbyId function
      this.selected_items.some((selected_item, index) => {
        if (isEqual(item, selected_item)) {
          this.selected_items.splice(index, 1)
          return true
        }
      })
    },
    getShiftSelectionRows(rowIndex) {
      let start = 0
      let end = 0
      if (this.lastSelectedItemIndex < rowIndex) {
        start = this.lastSelectedItemIndex
        end = rowIndex + 1
      } else if (this.lastSelectedItemIndex > rowIndex) {
        start = rowIndex
        end = this.lastSelectedItemIndex + 1
      }
      return this.vbt_rows.slice(start, end)
    },
    updateFilter(payload) {
      const value = typeof payload.value == 'number' ? payload.value.toString() : payload.value
      const column = payload.column
      const filter_index = findIndex(this.query.filters, {
        name: column.name,
      })
      if (filter_index == -1) {
        if (value !== '') {
          this.query.filters.push({
            type: column.filter.type,
            name: column.name,
            text: value.trim(),
            config: column.filter,
          })
        }
      } else {
        if (value === '') {
          this.query.filters.splice(filter_index, 1)
        } else {
          this.query.filters[filter_index].text = value.trim()
        }
      }
    },
    updateMultiSelectFilter(payload) {
      const _this = this
      const selected_options = payload.selected_options
      const column = payload.column

      const filter_index = findIndex(this.query.filters, {
        name: column.name,
      })

      if (column.filter.type === 'aciso_custom' || column.filter.type === 'custom_bool') {
        const column_index = findIndex(this.vbt_columns, {
          name: column.name,
        })
        const filter_index = findIndex(_this.vbt_columns[column_index].filter.options, {
          value: selected_options[0],
        })
        if (filter_index) {
          _this.vbt_columns[column_index].filter.init = { value: filter_index }
        }
      }

      if (filter_index == -1) {
        if (selected_options.length === 0) {
          return
        }
        this.query.filters.push({
          type: column.filter.type,
          mode: column.filter.mode,
          name: column.name,
          selected_options: selected_options,
          config: column.filter,
        })
      } else {
        if (selected_options.length === 0) {
          this.query.filters.splice(filter_index, 1)
        } else {
          this.query.filters[filter_index].selected_options = selected_options
        }
      }
    },

    sort() {
      if (this.query.sort.length != 0) {
        const orders = this.query.sort.map((sortConfig) => sortConfig.order)
        this.temp_filtered_results = orderBy(
          this.temp_filtered_results,
          this.query.sort.map((sortConfig) => {
            return (row) => {
              let value
              if (sortConfig.cb) {
                value = sortConfig.cb(get(row, sortConfig.name))
              } else {
                value = get(row, sortConfig.name)
              }
              if (sortConfig.is_number) {
                return !isNaN(value) ? +value : -1
              }
              if (sortConfig.caseSensitive) {
                return value
              }
              // First REGEX is for cleaning up accents and second REGEX for strings with multiple spaces
              return value != null
                ? value
                    .toString()
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/\s+/g, ' ')
                : ''
            }
          }),
          orders
        )
        //  Filter a list of objects, excluding those with an ID set to 'all'.
        if (this.temp_filtered_results.some((fr) => fr.id === 'all')) {
          const indexAll = this.temp_filtered_results.findIndex((objet) => objet.id === 'all')
          if (indexAll !== -1) {
            const objetAll = this.temp_filtered_results.splice(indexAll, 1)[0]
            this.temp_filtered_results.push(objetAll)
          }
        }
      }

      this.paginateFilter()
    },

    filter(resetPage = true, isInit = false) {
      const res = filter(this.original_rows, (row) => {
        let flag = true
        this.query.filters.some((filter) => {
          if (filter.type === 'simple') {
            if (this.simpleFilter(get(row, filter.name), filter.text, filter.config)) {
              // continue to next filter
              flag = true
            } else {
              // stop here and break loop since one filter has failed
              flag = false
              return true
            }
          } else if (filter.type === 'select') {
            if (filter.selected_options[0] === 'All') {
              flag = true
            } else if (filter.selected_options[0] === 'None') {
              if (!get(row, filter.name) || get(row, filter.name) === '') {
                flag = true
              } else {
                flag = false
              }
            } else {
              if (this.multiSelectFilter(get(row, filter.name), filter.selected_options, filter.config)) {
                flag = true
              } else {
                flag = false
                return true
              }
            }
          } else if (filter.type === 'custom') {
            const index = findIndex(this.vbt_columns, { name: filter.name })
            if (index > -1) {
              const column = this.vbt_columns[index]
              if (column.filter.validator) {
                const result = column.filter.validator(get(row, filter.name), filter.text)
                if (result == true || result == undefined) {
                  flag = true
                } else {
                  flag = false
                  return true
                }
              } else {
                flag = true
              }
            } else {
              flag = true
            }
          } else if (filter.type === 'aciso_custom') {
            if (filter.selected_options[0] > 0) {
              if (row[filter.name].some((campaigns) => campaigns.id === filter.selected_options[0])) {
                flag = true
              } else {
                flag = false
                return true
              }
            } else if (filter.selected_options[0] === 'All') {
              flag = true
            } else {
              if (row[filter.name].length === 0) {
                flag = true
              } else {
                flag = false
                return true
              }
            }
          } else if (filter.type === 'custom_bool') {
            if (filter.selected_options[0] > 0) {
              if (row.warning.status === filter.selected_options[0]) {
                flag = true
              } else {
                flag = false
                return true
              }
            } else if (filter.selected_options[0] === 'All') {
              flag = true
            } else if (filter.selected_options[0] === false) {
              if (row.warning.status === false) {
                flag = true
              } else {
                flag = false
                return true
              }
            } else {
              flag = false
              return true
            }
          }
        })
        return flag
      })

      this.temp_filtered_results = res
      this.$emit('update:length', this.temp_filtered_results.length)

      // Do global search only if global search text is not empty and
      // filtered results is also not empty
      if (this.query.global_search !== '' && this.rowCount != 0) {
        this.temp_filtered_results = this.globalSearch(this.temp_filtered_results)
      }

      this.sort()
      if (resetPage || this.rowCount == 0) {
        this.page = 1
      } else if (!isInit) {
        const newTotalPage = Math.ceil(this.rowCount / this.per_page)
        this.page = this.page <= newTotalPage ? this.page : newTotalPage
      }
    },
    showActionBar(show) {
      if (show === false) {
        this.show_actions = false
      } else {
        setTimeout(() => (this.show_actions = true), 400)
      }
    },
    globalSearch(temp_filtered_results) {
      let global_search_text = this.query.global_search
      if (typeof global_search_text !== 'string') {
        global_search_text = global_search_text.toString()
      }
      if (!this.global_search.case_sensitive) {
        global_search_text = global_search_text.toLowerCase()
      }
      const global_search_results = filter(temp_filtered_results, (row) => {
        let flag = false

        this.vbt_columns.some((vbt_column) => {
          let value = get(row, vbt_column.name)

          if (value == null || typeof value === 'undefined') {
            value = ''
          }

          if (typeof value !== 'string') {
            value = value.toString()
          }

          if (typeof global_search_text !== 'string') {
            global_search_text = global_search_text.toString()
          }

          if (!this.global_search.case_sensitive) {
            value = value.toLowerCase()
          }

          if (value.indexOf(global_search_text) > -1) {
            flag = true
          }
        })

        this.global_search.fields.some((f) => {
          let value
          if (typeof f === 'object') {
            value = get(row, f.prop)
          } else {
            value = get(row, f)
          }

          if (value == null || typeof value === 'undefined') {
            value = ''
          }

          if (typeof value !== 'string') {
            if (Array.isArray(value)) {
              value.forEach((v) => {
                if (f === 'functions_array') {
                  if (v.toLowerCase().includes(global_search_text.toLowerCase())) {
                    flag = true
                    return flag
                  }
                } else {
                  if (!this.global_search.case_sensitive) {
                    v[f.attr].toLowerCase()
                  }
                  if (v[f.attr].toLowerCase().includes(global_search_text.toLowerCase())) {
                    flag = true
                    return flag
                  }
                }
              })
            } else {
              value = value.toString()
            }
          }

          if (!this.global_search.case_sensitive && typeof value === 'string') {
            value = value.toLowerCase()
          }

          if (value.indexOf(global_search_text) > -1) {
            flag = true
          }
        })

        return flag
      })

      return global_search_results
    },

    simpleFilter(value, filter_text, config) {
      if (value == null || typeof value === 'undefined') {
        value = ''
      }

      if (typeof value !== 'string') {
        value = value.toString()
      }

      if (typeof filter_text !== 'string') {
        value = filter_text.toString()
      }

      const is_case_sensitive = has(config, 'case_sensitive') ? config.case_sensitive : false

      if (!is_case_sensitive) {
        value = value.toLowerCase()
        filter_text = filter_text.toLowerCase()
      }

      return value.indexOf(filter_text) > -1
    },
    multiSelectFilter(value, selected_options) {
      if (value == null || typeof value === 'undefined') {
        value = ''
      }

      if (typeof value !== 'string') {
        value = value.toString().toLowerCase()
      } else {
        value = value.toLowerCase()
      }

      selected_options = map(selected_options, (option) => {
        return typeof option !== 'string' ? option.toString().toLowerCase() : option.toLowerCase()
      })
      return includes(selected_options, value)
    },

    paginateFilter() {
      if (this.pagination) {
        const start = (this.page - 1) * this.per_page
        const end = start + this.per_page
        this.vbt_rows = this.temp_filtered_results.slice(start, end)
      } else {
        this.vbt_rows = cloneDeep(this.temp_filtered_results)
      }
      this.addSticky()
    },
    addSticky() {
      const index = this.vbt_rows.findIndex((r) => r.attr === '_sticky_')
      if (index >= 0) {
        this.vbt_rows.splice(index, 1)
        if (this.sticky_) {
          this.vbt_rows.push({ attr: '_sticky_' })
        }
      } else {
        if (this.sticky_) {
          this.vbt_rows.push({ attr: '_sticky_' })
        }
      }
    },
    selectAllCheckbox() {
      if (this.allRowsSelected) {
        this.unSelectAllItems()
        this.allRowsSelected = false
      } else {
        this.selectAllItems()
        this.allRowsSelected = true
      }
    },

    isSortableColumn(column) {
      if (!has(column, 'sort')) {
        return false
      } else {
        return column.sort
      }
    },
    // row method starts here
    getValueFromRow(row, name) {
      return get(row, name)
    },
    getCellSlotName(column) {
      if (has(column, 'slot_name')) {
        return column.slot_name
      }
      return column.name.replace(/\./g, '_')
    },
    // row method ends here
    resetSort() {
      this.query.sort = []
      this.filter(!this.preservePageOnDataChange)
    },

    updateGlobalSearchHandler: function (value) {
      this.query.global_search = value
    },

    clearGlobalSearch() {
      this.query.global_search = ''
      this.$refs.global_search.value = ''
      this.$log.debug('Cleared')
      $('.g-search').val('')
    },

    resetQuery() {
      this.query = {
        sort: [],
        filters: [],
        global_search: '',
      }

      this.global_search.visibility && (this.$refs.global_search.value = '')

      EventBus.$emit('reset-query')
    },

    emitQueryParams(page = null) {
      if (this.server_mode && this.canEmitQueries) {
        const queryParams = cloneDeep(this.query)
        const sort = map(queryParams.sort, (o) => omit(o, 'vbt_col_id'))
        const filters = map(queryParams.filters, (o) => omit(o, 'config'))
        const global_search = queryParams.global_search
        const per_page = clone(this.per_page)

        this.$log.debug(per_page)
        if (page == null) {
          if (this.preservePageOnDataChange) {
            page = this.page
          } else {
            this.page = 1
            page = 1
          }
        }

        const payload = {
          sort: sort,
          filters: filters,
          global_search: global_search,
          per_page: per_page,
          page: page,
        }

        this.$emit('on-change-query', payload)
      }
    },
    columnClasses(column) {
      let classes = ''

      const default_text_alignment = 'text-center'

      //decide text alignment class - starts here
      const alignments = ['text-justify', 'text-right', 'text-left', 'text-center']
      if (has(column, 'column_text_alignment') && includes(alignments, column.column_text_alignment)) {
        classes = classes + ' ' + column.column_text_alignment
      } else {
        classes = classes + ' ' + default_text_alignment
      }
      //decide text alignment class - ends here

      // adding user defined classes to rows - starts here
      if (has(column, 'column_classes')) {
        classes = classes + ' ' + column.column_classes
      }
      // adding user defined classes to rows - ends here

      // adding classes for sortable column - starts here
      if (this.isSortableColumn(column)) {
        classes = classes + ' vbt-sort-cursor'
      }
      // adding classes for sortable column - ends here

      // Prevent column to resize if there are rows or no
      if (
        has(column, 'row_classes') &&
        column.row_classes &&
        column.row_classes.match('w-\\d{1,3}|w_\\d{1,3}') &&
        column.row_classes.match('w-\\d{1,3}|w_\\d{1,3}').length > 0
      ) {
        column.row_classes.match('w-\\d{1,3}|w_\\d{1,3}').forEach((r) => {
          classes = classes + ' ' + r
        })
      }

      return classes
    },

    handleShiftKey() {
      ;['keyup', 'keydown'].forEach((event) => {
        window.addEventListener(event, (e) => {
          document.onselectstart = function () {
            return !(e.key == 'Shift' && e.shiftKey == true)
          }
        })
      })
    },
    emitActionEvent(action) {
      const payload = {
        event_payload: cloneDeep(action.event_payload),
      }

      if (this.isSelectable) {
        payload.selectedItems = cloneDeep(this.selected_items)
      }

      this.$emit(action.event_name, payload)
    },
    emitCheckboxEvent(action) {
      const payload = {
        event_payload: cloneDeep(action.event_payload),
      }
      if (this.isSelectable) {
        payload.selectedItems = cloneDeep(this.selected_items)
      }
      const event = {
        event_name: action.event_name,
        value: action.value,
      }
      this.$log.debug('EVENT2', event)
      this.$emit('on-checkbox', event)
    },
    emitToggleEvent(action) {
      const payload = {
        event_payload: cloneDeep(action.event_payload),
      }
      if (this.isSelectable) {
        payload.selectedItems = cloneDeep(this.selected_items)
      }
      const event = {
        event_name: action.event_name,
        value: action.value,
      }
      this.$emit('on-toggle', event)
    },
    canShowColumn(column) {
      return column.visibility == undefined || column.visibility ? true : false
    },
  },
}
</script>

<style scoped>
.ghost {
  opacity: 0.3;
  background: #2c5263;
}

.vbt-select-all-checkbox {
  margin-bottom: 20px;
}

.sticky_row {
  border-top: 2px solid #dee2e6 !important;
  background: white !important;
  box-shadow: none !important;
  pointer-events: none !important;
}

.vbt-sort-cursor {
  cursor: pointer;
}

.custom-control-label {
  vertical-align: top;
}

.vbt-column-header {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.vbt-global-search-clear {
  cursor: pointer;
}

input[type='search'] {
  -webkit-appearance: searchfield;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

/* Bootstrap 4 text input with clear icon on the right */

.has-clear-right {
  position: relative;
}

.has-clear-right .form-control {
  padding-right: 2.375rem;
}

.has-clear-right .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  font-weight: normal;
}

.has-clear-right .form-control-feedback:hover {
  color: red;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0,
    rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0,
    rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.selected_row {
  background-color: #dfecff;
}

.linked_row {
  background-color: #dfecff;
}

.linked_row:hover {
  background-color: #dfecff !important;
}
</style>

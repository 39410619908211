<template>
  <div class="ndsSpinner">
    <component
      :is="getSpinnerComponent"
      class="-animateSpin"
      :style="{ fill: color }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import NdsIconSpinner from '@/components/NewDesignSystem/Icons/NdsIconSpinner.vue'
import NdsIconSpinnerCompact from '@/components/NewDesignSystem/Icons/NdsIconSpinnerCompact.vue'
import { NdsSpinnerSize } from '@/components/NewDesignSystem/NdsSpinner/NdsSpinner.interfaces'

import { NdsColor } from '@/interfaces/NdsColors.interfaces'

const props = defineProps({
  size: {
    type: String,
    default: NdsSpinnerSize.DEFAULT,
  },
  color: {
    type: String,
    default: NdsColor.BLACK,
  },
})

const getSpinnerComponent = computed(() => {
  return props.size === NdsSpinnerSize.COMPACT ? NdsIconSpinnerCompact : NdsIconSpinner
})
</script>

<style lang="postcss" scoped>
.ndsSpinner {
  & .-animateSpin {
    @apply tw-animate-spin;
  }
}
</style>

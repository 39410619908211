<template>
  <button
    :class="getClasses"
    :data-selector="dataSelector"
    :type="buttonType"
    @click="onClick"
  >
    <span
      class="ndsButton__slot"
      :class="{
        loading: loading || isBusy,
      }"
      data-testid="button-slot"
    >
      <NdsIcon
        v-if="iconLeft"
        class="ndsButton__icon"
        data-testid="button-icon-left"
        :icon="iconLeft"
        :size="iconSize[size]"
      />

      <slot />

      <NdsIcon
        v-if="iconRight"
        class="ndsButton__icon"
        data-testid="button-icon-right"
        :icon="iconRight"
        :size="iconSize[size]"
      />
    </span>

    <span
      v-if="loading || isBusy"
      class="loader"
    >
      <NdsSpinner
        :color="loaderColor"
        :size="NdsSpinnerSize.COMPACT"
      />
    </span>
  </button>
</template>

<script setup lang="ts">
import { ref, computed, type PropType } from 'vue'

import type { NdsIconType } from '@/components/NewDesignSystem/NdsIcon/NdsIcon.interfaces'
import { NdsSpinnerSize } from '@/components/NewDesignSystem/NdsSpinner/NdsSpinner.interfaces'
import {
  NdsButtonColor,
  NdsButtonSize,
  NdsButtonType,
} from '@/components/NewDesignSystem/NdsButton/NdsButton.interfaces'
import { NdsColor } from '@/interfaces/NdsColors.interfaces'

import NdsSpinner from '@/components/NewDesignSystem/NdsSpinner/NdsSpinner.vue'
import NdsIcon from '@/components/NewDesignSystem/NdsIcon/NdsIcon.vue'

const props = defineProps({
  dataSelector: {
    type: String,
    required: false,
    default: 'button',
  },
  size: {
    type: String as PropType<NdsButtonSize>,
    default: NdsButtonSize.M,
  },
  color: {
    type: String as PropType<NdsButtonColor>,
    default: NdsButtonColor.PRIMARY,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  action: {
    type: [Function, null] as PropType<(() => unknown) | (() => Promise<unknown>) | null>,
    default: null,
  },
  iconLeft: {
    type: [String, null] as PropType<NdsIconType | null>,
    default: null,
  },
  iconRight: {
    type: [String, null] as PropType<NdsIconType | null>,
    default: null,
  },
  buttonType: {
    type: String as PropType<NdsButtonType>,
    default: NdsButtonType.BUTTON,
  },
})

const emit = defineEmits(['click'])

const isBusy = ref(false)

const iconSize: Record<NdsButtonSize, number> = {
  [NdsButtonSize.L]: 20,
  [NdsButtonSize.M]: 16,
  [NdsButtonSize.S]: 16,
  [NdsButtonSize.XS]: 16,
}

const getClasses = computed(() => {
  return `ndsButton -${props.size} -${props.color}`
})

const loaderColor = computed<string>(() => {
  return props.color === NdsButtonColor.SECONDARY
    ? NdsColor.NEUTRAL_950
    : props.color === NdsButtonColor.TERTIARY
    ? NdsColor.NEUTRAL_950
    : props.color === NdsButtonColor.DESTRUCTIVE
    ? NdsColor.DANGER_500
    : NdsColor.WHITE
})

const onClick = async () => {
  if (props.action !== null && !isBusy.value) {
    isBusy.value = true
    await props.action()
    isBusy.value = false
  }
  emit('click')
}
</script>

<style lang="postcss" scoped>
.ndsButton {
  display: inline-flex;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  font-style: normal;
  border: 1px solid transparent;
  gap: 8px;
  align-items: center;
  position: relative;

  &__slot {
    display: flex;
    align-items: center;
    gap: 8px;
    text-wrap: nowrap;

    &.loading {
      opacity: 0;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: wait;
  }

  &.loading {
    position: absolute;
  }

  &.-xSmall {
    height: 24px;
    padding: 4px;
    font-size: 12px;
    line-height: 16px;
  }

  &.-small {
    height: 32px;
    padding: 8px;
    font-size: 14px;
    line-height: 16px;
  }

  &.-medium {
    height: 40px;
    padding: 12px;
    font-size: 14px;
    line-height: 16px;
  }

  &.-large {
    height: 52px;
    padding: 16px;
    font-size: 16px;
    line-height: 20px;
  }

  &.-primary {
    @apply tw-bg-primary-500;
    color: white;

    &::v-deep .nds-icon {
      @apply !tw-text-white;
      @apply !tw-stroke-white;
    }

    &:hover {
      @apply tw-bg-primary-600;
    }

    &:active {
      @apply tw-bg-primary-700;
    }
  }

  &.-secondary {
    background-color: white;
    border-width: 1px;
    border-style: solid;
    @apply tw-border-neutral-200;
    @apply tw-text-neutral-950;

    &::v-deep .nds-icon {
      @apply !tw-text-neutral-950;
      @apply !tw-stroke-neutral-950;
    }

    &:hover {
      @apply tw-bg-neutral-50;
    }

    &:active {
      @apply tw-bg-neutral-100;
    }
  }

  &.-tertiary {
    background-color: transparent;
    border: 1px solid transparent;
    @apply tw-text-primary-500;

    &::v-deep .nds-icon {
      @apply !tw-text-primary-500;
      @apply !tw-stroke-primary-500;
    }

    &:hover {
      @apply tw-bg-alpha-700;
    }

    &:active {
      @apply tw-bg-neutral-100;
    }

    &:disabled,
    &:disabled:hover {
      background-color: transparent;
      @apply tw-text-neutral-500;
    }
  }

  &.-destructive {
    @apply tw-bg-danger-50;
    border-width: 1px;
    border-style: solid;
    @apply tw-border-danger-50;
    @apply tw-text-danger-500;

    &::v-deep .nds-icon {
      @apply !tw-text-danger-500;
      @apply !tw-stroke-danger-500;
    }

    &:hover {
      @apply tw-bg-danger-100;
    }

    &:active {
      @apply tw-bg-danger-200;
    }
  }

  /* To be merged with tertiary when design is done */

  &.-transparent {
    @apply tw-text-neutral-900;
    background-color: transparent;
    border-color: transparent;

    &:hover {
      background-color: rgba(35, 36, 36, 0.07);
    }
  }

  &:disabled {
    @apply tw-bg-neutral-200;
    @apply tw-text-neutral-700;
    cursor: not-allowed;

    &::v-deep .nds-icon {
      @apply !tw-text-neutral-700;
      @apply !tw-stroke-neutral-700;
    }

    &:hover {
      @apply tw-bg-neutral-200;
      @apply tw-text-neutral-700;
    }

    &.ghost {
      background-color: transparent;
    }
  }
}
</style>

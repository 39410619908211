export enum NdsButtonSize {
  L = 'large',
  M = 'medium',
  S = 'small',
  XS = 'xSmall',
}

export enum NdsButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TRANSPARENT = 'transparent',
  DESTRUCTIVE = 'destructive',
}

export enum NdsButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}

<template>
  <div class="layout-navbar-fixed">
    <nav
      class="main-header navbar navbar-expand bg-light layout-navbar-fixed"
      :class="{ 'ml-0': share }"
    >
      <div class="w-100">
        <b-row
          class="main-header1"
          style="align-items: center"
        >
          <b-col>
            <template v-if="!($route.meta.do_not_show && $route.meta.do_not_show == true)">
              <page-header-title />
            </template>
          </b-col>
          <div class="float-right mr-2">
            <ul
              class="navbar-nav ml-auto w-100"
              style="align-items: center"
            >
              <li
                v-if="$store.getters.isSandbox"
                class="mr-4"
              >
                <i class="fa fa-exclamation-triangle text-warning" /><span class="ml-2"><i>sandbox</i></span>
              </li>
              <li
                v-if="share"
                class="nav-item mr-2 share_lang"
              >
                <b-dropdown
                  ref="dropthree2"
                  class="mousehoverextend"
                  no-caret
                  variant="none"
                  @mouseleave.native="onLeave('dropthree2')"
                  @mouseover.native="onOver('dropthree2')"
                >
                  <template #button-content>
                    <span v-if="$store.getters.lang === 'en'">English </span>
                    <span v-if="$store.getters.lang === 'fr'">Français </span>
                  </template>
                  <b-dropdown-item
                    v-if="$store.getters.lang === 'en'"
                    @click="setShareLang('fr')"
                  >
                    <div>
                      <flag
                        class="mr-2"
                        iso="fr"
                      />
                      <span
                        class="bg-white"
                        :class="{ 'font-weight-bold': $store.getters.lang === 'fr' }"
                        data-testid="btn-switch-french"
                      >
                        Français
                      </span>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$store.getters.lang === 'fr'"
                    @click="setShareLang('en')"
                  >
                    <div>
                      <flag
                        class="mr-2"
                        iso="gb"
                      />
                      <span
                        class="bg-white"
                        :class="{ 'font-weight-bold': $store.getters.lang === 'en' }"
                        data-testid="btn-switch-english"
                      >
                        English
                      </span>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </li>

              <li
                v-if="share"
                class="nav-item"
              >
                <a
                  href="https://www.tenacy.io"
                  rel="noopener"
                  target="_blank"
                >
                  <img
                    alt="TENACY"
                    class="brand-image pull-right"
                    :src="`${publicPath}static/img/Tenacy_couleur_borderless.svg`"
                    style="height: 25px !important"
                  />
                </a>
                <span class="text-black valign-middle no-mobile pull-right mr-2">
                  <translate>Powered by</translate>
                </span>
              </li>
              <li
                v-if="!share"
                class="nav-item show test mr-0"
              >
                <app-icon
                  v-if="!$store.getters.isAuditor && !$store.getters.isPublic && !$store.getters.isOnlyContributor"
                  class="icon-header helper"
                  :color="has_reviews_ ? 'warning' : 'primary'"
                  :custom_icon="has_reviews_ ? 'bell-on' : 'bell'"
                  :fa_type="'far'"
                  :tooltip="title_review"
                  @click="showReview"
                />
                <app-icon
                  class="icon-header helper"
                  color="primary"
                  :custom_icon="'lightbulb-on'"
                  data-testid="news-icon"
                  :fa_type="'far'"
                  @click="showNewsDyk"
                />
                <app-icon
                  class="icon-header helper"
                  :color="'primary'"
                  :custom_icon="'question-circle'"
                  :fa_type="'far'"
                  @click="showHelp"
                />
              </li>
              <li
                v-if="routes_wheel && routes_wheel.length > 0 && !share && !$store.getters.isPublic"
                class="nav-item show test"
              >
                <b-dropdown
                  ref="dropone"
                  class="mousehoverextend helper pl-0"
                  no-caret
                  variant="none"
                  @mouseleave.native="onLeave('dropone')"
                  @mouseover.native="onOver('dropone')"
                >
                  <template #button-content>
                    <app-icon
                      class="icon-header helper"
                      :color="'primary'"
                      :custom_icon="'cog'"
                      data-testid="toggle-wheel-menu"
                      :fa_type="'far'"
                      style="margin-top: -2px"
                    />
                  </template>
                  <div>
                    <template>
                      <b-dropdown-item
                        v-for="route_wheel in routes_wheel"
                        :key="route_wheel.name"
                        class="p-0 w-100"
                        @click="doRefresh($event, route_wheel)"
                      >
                        <div class="m-0 w-100">
                          <a
                            :id="route_wheel.name"
                            class="item-active"
                            :class="{
                              'font-weight-bold': isActive(route_wheel),
                              width: '100%',
                              display: 'flex',
                            }"
                          >
                            <app-icon
                              class="icon-menu-wheel"
                              :color="'primary'"
                              :custom_icon="route_wheel.meta.icon"
                              :fa_type="'far'"
                              :icon_size="'1rem'"
                              @click="doRefresh($event, route_wheel)"
                            />
                            <span
                              v-if="route_wheel.meta.i18nTitle"
                              class="text-primary"
                              :data-testid="route_wheel.meta.title"
                            >
                              {{ t(route_wheel.meta.i18nTitle) }}
                            </span>
                            <span
                              v-else
                              class="text-primary"
                              :data-testid="route_wheel.meta.title"
                            >
                              <translate>{{ route_wheel.meta.title }}</translate>
                            </span>
                          </a>
                        </div>
                      </b-dropdown-item>
                    </template>
                  </div>
                </b-dropdown>
              </li>

              <li
                v-if="$store.getters.customers.length > 1 && !share"
                class="nav-item show"
              >
                <b-dropdown
                  ref="droptwo"
                  class="mousehoverextend"
                  no-caret
                  variant="none"
                  @mouseleave.native="onLeave('droptwo')"
                  @mouseover.native="onOver('droptwo')"
                >
                  <template #button-content>
                    <span v-if="$store.getters.customer === $store.getters.none_label">
                      <app-icon
                        class="icon-menu-wheel"
                        :color="'primary'"
                        :custom_icon="'server'"
                        :fa_type="'far'"
                        :icon_size="'1rem'"
                      />
                      <span>{{ $store.getters.instance }}</span>
                    </span>
                    <span v-else>
                      {{ $store.getters.customer }}
                    </span>
                  </template>
                  <b-dropdown-item
                    v-for="customer in customers"
                    :key="customer.id"
                  >
                    <div @click="setCustomer(customer.id)">
                      <template v-if="customer.name !== $store.getters.none_label">
                        <span class="text-primary">
                          <app-icon
                            class="icon-menu-wheel"
                            :color="'primary'"
                            :custom_icon="'user-circle'"
                            :fa_type="'far'"
                            :icon_size="'1rem'"
                          />
                        </span>
                        <span class="text-primary">{{ customer.name }} </span>
                      </template>
                      <template v-else>
                        <span class="text-primary">
                          <app-icon
                            class="icon-menu-wheel"
                            :color="'primary'"
                            :custom_icon="'server'"
                            :fa_type="'far'"
                            :icon_size="'1rem'"
                          />
                        </span>
                        <span class="text-primary">{{ $store.getters.instance }}</span>
                      </template>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </li>
              <li
                v-else-if="$store.getters.customers.length >= 0 && !share"
                class="nav-item mr-1 show"
              >
                <div class="nav-link customer">
                  <span v-if="$store.getters.customer === $store.getters.none_label">
                    <app-icon
                      class="icon-menu-wheel"
                      :color="'primary'"
                      :custom_icon="'server'"
                      :fa_type="'far'"
                      :icon_size="'1rem'"
                    />
                    <span>{{ $store.getters.instance }}</span>
                  </span>
                  <span v-else>
                    {{ $store.getters.customer }}
                  </span>
                </div>
              </li>
              <li
                v-if="!share"
                class="nav-item show test"
                data-testid="toggle-user-menu"
                style="cursor: pointer; padding-left: 10px; padding-right: 10px"
              >
                <b-dropdown
                  ref="dropthree"
                  class="mousehoverextend"
                  no-caret
                  variant="none"
                  @mouseleave.native="onLeave('dropthree')"
                  @mouseover.native="onOver('dropthree')"
                >
                  <template #button-content>
                    <span class="user-name">
                      {{ $store.getters.name }}
                    </span>
                  </template>
                  <b-dropdown-item @click="setLang('fr_FR')">
                    <div>
                      <flag
                        class="mr-2"
                        iso="fr"
                      />
                      <span
                        class="bg-white"
                        :class="{ 'font-weight-bold': $store.getters.lang === 'fr' }"
                        data-testid="btn-switch-french"
                      >
                        Français
                      </span>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item @click="setLang('en_US')">
                    <div>
                      <flag
                        class="mr-2"
                        iso="gb"
                      />
                      <span
                        class="bg-white"
                        :class="{ 'font-weight-bold': $store.getters.lang === 'en' }"
                        data-testid="btn-switch-english"
                      >
                        English
                      </span>
                    </div>
                  </b-dropdown-item>
                  <div class="dropdown-divider" />
                  <b-dropdown-item
                    v-if="!$store.getters.isPublic && $store.getters.isRw"
                    data-testid="my-objects"
                    @click="$router.push({ name: 'my_objects' })"
                  >
                    <div>
                      <app-icon
                        class="icon-menu-wheel"
                        :color="'primary'"
                        :custom_icon="'user-check'"
                        :fa_type="'far'"
                        :icon_size="'1rem'"
                        style="padding-left: 2px"
                      />
                      <span class="text-primary"><translate key="2791">My objects</translate></span>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item @click="current_profile = {}">
                    <div>
                      <app-icon
                        class="icon-menu-wheel"
                        :color="'primary'"
                        :custom_icon="'user-circle'"
                        :fa_type="'far'"
                        :icon_size="'1rem'"
                      />
                      <span
                        class="text-primary"
                        data-testid="edit-profile"
                        ><translate key="2801">Edit profile</translate></span
                      >
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item @click="current_password = {}">
                    <div>
                      <app-icon
                        class="icon-menu-wheel"
                        :color="'primary'"
                        :custom_icon="'lock'"
                        :fa_type="'far'"
                        :icon_size="'1rem'"
                      />
                      <span
                        class="text-primary"
                        data-testid="change-password"
                        ><translate key="2811">Change password</translate></span
                      >
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$store.getters.impersonate_contributor"
                    @click="impersonate(false)"
                  >
                    <div>
                      <app-icon
                        class="icon-menu-wheel"
                        :color="'primary'"
                        :custom_icon="'user-ninja'"
                        :fa_type="'far'"
                        :icon_size="'1rem'"
                      />
                      <span class="text-primary"><translate key="2821">Normal mode</translate></span>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-else-if="$store.getters.isPilote && !$store.getters.isPublic"
                    @click="impersonate(true)"
                  >
                    <div>
                      <app-icon
                        class="icon-menu-wheel"
                        :color="'primary'"
                        :custom_icon="'user-ninja'"
                        :fa_type="'far'"
                        :icon_size="'1rem'"
                      />
                      <span class="text-primary"><translate key="2831">Contributor mode</translate></span>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item @click="logout">
                    <div>
                      <app-icon
                        class="icon-menu-wheel"
                        :color="'primary'"
                        :custom_icon="'sign-out-alt'"
                        :fa_type="'far'"
                        :icon_size="'1rem'"
                      />
                      <span class="text-primary">{{ t('shared.signout') }}</span>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </li>
              <li v-if="$store.getters.impersonate_contributor">
                <i
                  v-b-tooltip
                  class="mr-3 ml-0 far helper text-size-main-header red fa-user-ninja"
                  :title="_('Contributor mode')"
                />
              </li>
            </ul>
          </div>
        </b-row>
        <b-row
          v-if="show_sub_menu"
          align-v="end"
          class="main-header2"
        >
          <b-nav
            class="navbar-items"
            pills
          >
            <router-link
              v-for="route in routes"
              :key="route.path"
              v-slot="{ href }"
              custom
              :to="route"
            >
              <!-- Following href is trusted -->
              <b-nav-item
                :active="isActive(route)"
                active-class="active"
                class="header-nav-item"
                :data-testid="route.name"
                :href="href"
                @click.prevent="isDisabled(route) ? showModal() : doRefresh($event, route)"
              >
                <template v-if="route.meta.i18nTitle">{{ t(route.meta.i18nTitle) }} </template>
                <translate v-else>{{ route.meta.title }} </translate>
                <b-badge
                  v-if="
                    route.meta.nb_contribution && $store.getters.getNbContribution(route.meta.nb_contribution) !== null
                  "
                  class="badge-nb-items"
                  variant="default"
                >
                  {{ $store.getters.getNbContribution(route.meta.nb_contribution) }}
                </b-badge>
                <span
                  v-else-if="route.meta.nb_contribution"
                  class="text-center ml-1"
                  style="flex: auto"
                  ><i class="fa fa-circle-notch fa-spin"
                /></span>
              </b-nav-item>
            </router-link>
          </b-nav>
        </b-row>
      </div>
    </nav>

    <user-password
      v-if="!share"
      ref="userPassword"
      api_endpoint_post="user/password"
      :current_obj_="current_password"
      current_operation="edit"
      modal_id="user_password"
      @cancelled="current_password = null"
    />

    <user-profile
      v-if="!share"
      ref="userProfile"
      api_endpoint_post="user/profile"
      :current_obj_="current_profile"
      current_operation="edit"
      do_get
      modal_id="user_profile"
      @cancelled="current_profile = null"
      @edited="profileEdited"
    />

    <wiki
      v-if="!share"
      ref="edit_form"
      :current_obj_="current_page"
      :current_operation="'view'"
      :has-child="true"
      :history="doc_navigation"
      :is-documentation="is_documentation"
      :modal_id="modal_id"
      :size="size_wiki"
      @cancelled=";(current_page = null), (doc_navigation = [])"
      @childNav="childNavigation"
      @previousPage="goPreviousPage"
    />

    <NewsPanel
      :show-news-panel="showNewsPanel"
      @close="showNewsPanel = false"
    />

    <aciso-tree
      v-if="!share"
      ref="aciso_tree"
      :modal_id="'aciso_tree'"
    />
    <modal-review
      v-if="!share"
      ref="modal_review"
    />
  </div>
</template>

<script>
import UserPassword from '../forms/user-password.vue'
import UserProfile from '../forms/user-profile.vue'
import Wiki from '../layout/wiki.vue'
import PageHeaderTitle from '../layout/page-header-title.vue'
import AcisoTree from '@/components/ui/aciso-tree.vue'
import ModalReview from '@/components/pages/modal-review.vue'
import AppIcon from '../ui/app-icon.vue'
import { sortName } from '@/helpers/Sorting.helpers'
import NewsPanel from '@/contexts/shared/news/NewsPanel.vue'

export default {
  name: 'main-header',
  components: {
    NewsPanel,
    ModalReview,
    UserPassword,
    UserProfile,
    Wiki,
    AcisoTree,
    PageHeaderTitle,
    AppIcon,
  },
  props: {
    share: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: String,
      default: '',
    },
    isAdminNews: {
      type: Boolean,
    },
    isAdminDyk: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      isChildren: false,
      tree: {},
      current_password: null,
      current_profile: null,
      size_wiki: 'lg',
      is_documentation: true,
      current_page: null,
      current_rating: null,
      modal_id: 'help',
      identifier: null,
      api_endpoint: 'wiki',
      doc_navigation: [],
      loading_values: false,
      finish_loading: false,
      has_policy_reviews: false,
      publicPath: import.meta.env.BASE_URL,
      showNewsPanel: false,
    }
  },
  computed: {
    show_sub_menu() {
      let max_depth = 4
      if (this.$route.matched.length > 0 && this.$route.matched[0].path == '/nav') {
        max_depth = 5
      }
      return this.routes && this.routes.length > 0 && this.$route.matched.length < max_depth
    },
    title_review() {
      if (this.has_reviews_) {
        return this.$store.getters.nb_reviews + ' ' + this._('reviews required')
      } else {
        return this._('No review required')
      }
    },
    has_reviews_() {
      return this.$store.getters.nb_reviews > 0
    },
    customers() {
      if (this.$store.getters.impersonate_contributor) {
        return this.$store.getters.customers
          .filter((e) => e.name != this.$store.getters.customer && e.id > 0)
          .slice()
          .sort((a, b) => (a.id <= 0 ? -1 : b.id <= 0 ? 1 : sortName(a, b)))
          .slice(0, 12)
      } else {
        return this.$store.getters.customers
          .filter((e) => e.name != this.$store.getters.customer)
          .slice()
          .sort((a, b) => (a.id <= 0 ? -1 : b.id <= 0 ? 1 : sortName(a, b)))
          .slice(0, 12)
      }
    },
    routes() {
      const parent_multi = this.$route.matched.find((route) => route.meta.multi === true)
      if (parent_multi != null) {
        return this.$router
          .getRoutes()
          .filter(
            (route) =>
              route.parent &&
              route.parent.name === parent_multi.name &&
              (route.meta.is_authorized(route) ||
                (route.meta.is_optional === true &&
                  route.meta.is_authorized(route) &&
                  route.name !== 'risk-analysis')) &&
              route.parent.meta.multi
          )
      }
      return null
    },
    routes_wheel() {
      const wheel_main_route = this.$router.options.routes.find((e) => e.path === '/admin')
      return wheel_main_route.children.filter((e) => e.meta.is_authorized(e) !== false)
    },
  },
  watch: {
    $route() {
      this.$log.debug(this.$route)
      if (this.$refs.edit_form) {
        this.$refs.edit_form.hide()
      }
      if (this.$os_body) {
        this.$os_body.scroll({ y: 0 }, 1000)
      }
    },
  },
  mounted: function () {
    const _this = this
    this.$log.debug(this.$route)
    this.$root.$on('treeViz', function (e) {
      _this.$log.debug('EEE', e)
      _this.tree = e
      this.$nextTick(() => {
        if (_this.$refs.aciso_tree && _this.tree) {
          _this.$refs.aciso_tree.show(e)
        }
      })
    })
    this.$root.$on('modalReview', function (e) {
      _this.$log.debug('ModalReview', e)
      this.$nextTick(() => {
        if (_this.$refs.modal_review) {
          _this.$refs.modal_review.showReview(e)
        }
      })
    })
    this.$root.$on('integrationDoc', function (e) {
      this.$nextTick(() => {
        _this.loadPage(e)
      })
    })
    this.doc_navigation = []
    if (this.share) {
      this.setShareLang('fr')
    }
    const regex_download = new RegExp('^blob:')
    window.addEventListener('click', ($event) => {
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = $event

      while (target && target.tagName !== 'A') {
        target = target.parentNode
      }

      if (target && target.href) {
        if (target.matches("a[data-toggle='tab']")) {
          return
        }
        // handle only links that do not reference external resources
        let url = ''
        try {
          url = new URL(target.href)
        } catch (e) {
          this.$log.debug('ERROROOOO')
          return
        }

        const to = url.pathname
        if (target.matches("a:not([href*='://'])") && target.href && to.startsWith('/doc/')) {
          // some sanity checks taken from vue-router:
          // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
          const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = $event
          // don't handle with control keys
          if (metaKey || altKey || ctrlKey || shiftKey) {
            return
          }
          // don't handle when preventDefault called
          if (defaultPrevented) {
            return
          }
          // don't handle right clicks
          if (button !== undefined && button !== 0) {
            return
          }
          if (target.getAttribute) {
            const linkTarget = target.getAttribute('target')
            if (/\b_blank\b/i.test(linkTarget)) {
              return
            }
          }
          // don't handle same page links/anchors

          event.preventDefault()
          if (window.location.pathname !== to && $event.preventDefault) {
            if (to.includes('/doc/') && !window.location.pathname.startsWith('/doc/')) {
              const doc_identifier = to.substring(5)
              const _this = this
              if (doc_identifier) {
                if (this.current_page) {
                  this.loadPage(doc_identifier)
                } else if (this.current_news) {
                  this.goToChildren(doc_identifier)
                }
              } else {
                _this.$log.debug('No page to load')
              }
            } else {
              this.$log.debug('GO TO', to)
              this.$router.push(to)
            }
          }
        } else if (target.matches("a[href*='://']") && url.href && !url.href.match(regex_download)) {
          event.preventDefault()
          window.open(url, '_blank')
        }
      }
    })
    if (_this.isAdminNews && this.$route.name === 'home') {
      _this.showNewsDyk()
    } else if (_this.isAdminDyk && this.$route.name === 'home') {
      _this.showNewsDyk()
    }
  },
  methods: {
    isActive(route) {
      return this.$route.matched.some((r) => r.name === route.name)
    },
    doRefresh(event, route) {
      const routeToGo = this.$router.resolve(route)
      const route_name = routeToGo.route.name

      if (route_name === 'risk-analysis') {
        this.$store.commit('set_return_recap_from_header', true)
      } else {
        this.$store.commit('set_return_recap_from_header', false)
      }
      if (event.ctrlKey === true) {
        // if click + ctrl
        window.open(routeToGo.href, '_blank')
        return false
      } else {
        if (route_name === this.$route.name) {
          this.$root.$emit('l2nav', routeToGo.route)
        } else {
          this.$router.push(routeToGo.route).catch((err) => {
            this.$log.error(err)
          })
        }
      }
    },
    childNavigation(current_page) {
      const child_identifier = current_page.identifier
      if (child_identifier) {
        this.loadPage(child_identifier)
      }
    },
    goPreviousPage() {
      if (this.doc_navigation && this.doc_navigation.length > 1) {
        this.doc_navigation.pop()
        const new_identifier = this.doc_navigation[this.doc_navigation.length - 1]
        this.loadPage(new_identifier, true)
      }
    },
    showProfile: function () {
      this.current_profile = {}
    },
    showPasswordChange: function () {
      this.current_password = {}
    },
    setShareLang(lang) {
      this.$log.debug('SET LANG')
      this.$store.commit('set_lang', lang)
      this.$root.$emit('shareLangChange')
      if (lang === 'fr') {
        this.$moment.locale('fr_FR')
      } else if (lang === 'en') {
        this.$moment.locale('en_US')
      } else {
        this.$moment.locale('fr_FR')
      }
    },
    onOver(drop) {
      if (Array.isArray(this.$refs[drop])) {
        this.$refs[drop][0].visible = true
      } else {
        this.$refs[drop].visible = true
      }
    },
    onLeave(drop) {
      if (Array.isArray(this.$refs[drop])) {
        this.$refs[drop][0].visible = false
      } else {
        this.$refs[drop].visible = false
      }
    },
    change_password: function () {
      const _this = this
      _this.$log.debug('Changing password')
      _this.$bvModal.show('change_password')
    },
    to: function (route) {
      return { name: route.name }
    },
    logout: function () {
      const _this = this
      this.$log.debug('LOGOUT')
      this.$store.commit('loading')
      this.$store
        .dispatch('logout')
        .then(function () {
          _this.$store.commit('unloading')
          _this.$router.push('/login').then(() => {
            window.location.reload()
          })
        })
        .catch(function (err) {
          _this.$log.error('ERROR WHILE LOGOUT' + err)
          _this.$toast.alert(_this._('Logout error'))
          _this.$store.commit('unloading')
          _this.$router.push('/login')
        })
    },
    setLang: function (lang) {
      this.$language.current = lang
      this.$store
        .dispatch('setLang', lang)
        .then(() => {
          this.$language.current = lang
          this.$moment.locale(lang)
        })
        .catch((err) => {
          this.$toast.alert(this._('Language updated failed'))
          this.$log.error(err)
        })
    },
    impersonate: function (v) {
      this.$store
        .dispatch('impersonate', v)
        .then((res) => {
          this.$log.debug(res)
        })
        .catch((err) => {
          this.$toast.alert(this._('Mode changed failed'))
          this.$log.error(err)
        })
    },
    setCustomer: function (id) {
      this.$store
        .dispatch('setCustomer', id)
        .then(() => {
          this.$router
            .push({ name: this.$store.getters.isPiloteOrAuditor ? 'ghome' : 'lhome' })
            .then(() => {
              window.location.reload()
            })
            .catch(() => {
              window.location.reload()
            })
        })
        .catch((err) => {
          this.$log.error(err)
          this.$toast.alert(this._('Customer context update failed'))
        })
    },
    profileEdited: function (data) {
      this.$log.debug(data)
      if (data.object) {
        this.$store.dispatch('updateProfile', data.object)
      }
    },
    showHelp: function () {
      const _this = this
      this.size_wiki = 'lg'
      this.is_documentation = true
      if (this.$route.meta.doc_id) {
        this.identifier = this.$route.meta.doc_id
        this.loadPage(this.identifier)
      } else {
        _this.loadDocumentation()
      }
    },
    showReview: function () {
      if (this.$route.name !== 'review') {
        this.$router
          .push({ name: 'review' })
          .then(() => {})
          .catch((e) => {
            this.$log.debug(e)
          })
      }
    },
    showNewsDyk: function () {
      this.is_documentation = false
      this.showNewsPanel = true
    },
    loadDocumentation() {
      this.current_page = {}
    },
    loadPage(identifier, back = false) {
      const _this = this
      this.$http
        .get(this.api_endpoint + '/page/' + identifier)
        .then((resp) => {
          if (resp.data.ok) {
            this.current_page = resp.data.object
            this.$set(this.current_page, 'current_rating', resp.data.rating)
            this.$log.debug('CURRENT PAGE', this.current_page)
            if (!back) {
              this.doc_navigation.push(identifier)
            }
          } else {
            throw new Error()
          }
        })
        .catch((e) => {
          _this.doc_navigation.push('no-page')
          _this.loadDocumentation()
          _this.$log.debug(e)
        })
    },
    goToChildren(identifier, back = false) {
      const _this = this
      this.finish_loading = false
      setTimeout(() => {
        if (this.finish_loading === false) {
          _this.loading_values = true
        }
      }, 500)
      this.$http
        .get(this.api_endpoint + '/page/' + identifier)
        .then((resp) => {
          if (resp.data.ok) {
            this.finish_loading = true
            this.loading_values = false
            this.current_rating = resp.data.rating
            this.isChildren = true
            _this.$store.dispatch('updateNbReviews')
            if (!back) {
              this.doc_navigation.push(identifier)
            }
          } else {
            throw new Error()
          }
        })
        .catch((e) => {
          this.loading_values = false
          this.finish_loading = true
          _this.$log.debug(e)
        })
    },
    isDisabled(route) {
      if (route.meta) {
        return !route.meta.is_authorized(route)
      }
      return false
    },
    showModal() {
      this.$bvModal.show('analysis_disabled')
    },
  },
}
</script>

<style lang="scss" scoped>
.text-size-main-header {
  font-size: 130% !important;
}

.mousehoverextend {
  &:hover {
    &:before {
      content: '';
      position: absolute;
      top: 3%;
      right: 0px;
      left: 0;
      height: 55px;
      z-index: 99;
      box-sizing: border-box;
    }
  }
}

.helper {
  cursor: pointer;
}

.customer {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  cursor: pointer;
}

.brand-image {
  opacity: 1;
}

.navbar-badge {
  font-size: 0.6rem;
  font-weight: 300;
  padding: 2px 4px;
  z-index: 1000;
  position: relative;
}

.yellow {
  color: #ffd700;
}

.share_lang {
  margin-top: -2px !important;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .no-mobile {
    display: none;
  }
  /* hide it on small screen */
}

.bg-light {
  background-color: #f4f6f9 !important;
}

.main-header {
  border: none !important;
  padding-bottom: 0px;
}

.main-header1 {
  height: 60px !important;
  border: none !important;
}

.main-header2 {
  height: 40px !important;
  border: none !important;
}

.breadcrumb {
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 22px !important;
  padding: 0 !important;
  margin-top: 20px !important;
  margin-left: 25px !important;
  margin-bottom: 20px !important;
}

.user-name {
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
}

.icon-header {
  font-size: 20px !important;
  margin-right: 10px !important;
}

.navbar-items {
  padding-left: 10px !important;
}

.header-nav-item > .nav-link {
  font-size: 15px !important;
  line-height: 22px !important;
  padding-right: 24px !important;
  padding-left: 24px !important;
  margin-right: 10px;
  color: #161616 !important;
}

@media screen and (min-width: 1283px) and (max-width: 1443px) {
  .header-nav-item > .nav-link {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media screen and (min-width: 200px) and (max-width: 1282px) {
  .header-nav-item > .nav-link {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f4f6f9 !important;
  color: #161616 !important;
}

.header-nav-item > .nav-link:hover {
  font-weight: 400 !important;
  border-bottom: 3px #2c5263 solid !important;
  border-radius: 0px !important;
}

.header-nav-item > .nav-link.active {
  font-weight: 700 !important;
  border-bottom: 3px #2c5263 solid !important;
  border-radius: 0px !important;
}

.helper > .btn .dropdown-toggle .btn-none .dropdown-toggle-no-caret {
  padding-left: 0px !important;
}

.dropdown-toggle {
  white-space: nowrap !important;
  padding-left: 0px !important;
}

.header-nav-item > .nav-link.disabled {
  font-weight: 400 !important;
  border-bottom: 3px #8d8d8d00 solid !important;
  border-radius: 0px !important;
  color: #c6c6c6 !important;
}

.icon-menu-wheel {
  margin-right: 8px !important;
}

// TODO: Use a meta-variable for colors
.badge-nb-items {
  background-color: #b1b1b1;
  min-width: 18px;
  min-height: 18px;
  margin-left: 0.5em;
  color: white;
  font-weight: 700;
}
.nav-link.active .badge-nb-items {
  color: white;
  background-color: #0a4650;
}

@media (max-width: 1600px) {
  .main-header {
    margin-left: 71px !important;
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import * as Sentry from '@sentry/vue'
import { sentry_init_param } from './plugins/sentry'
import store from './store'
import Axios from 'axios'
import VueLogger from 'vuejs-logger'
import GetTextPlugin from 'vue-gettext'
import translations from './locales/fr_FR/translations.json'
import VeeValidate, { Validator } from 'vee-validate'
import FlagIcon from 'vue-flag-icon'
import PrettyCheckbox from 'pretty-checkbox-vue'
import vSelect from 'vue-select'
import BootstrapVue from 'bootstrap-vue'
import { config } from './config.settings'
import 'bootstrap'
import LiquorTree from 'liquor-tree'
import { fields_en, fields_fr } from './plugins/fields_tr'
import domtoimage from 'dom-to-image-more'
import jsZip from 'jszip'
import FileSaver from 'file-saver'
import VueToastify from 'vue-toastify'
import Nl2br from 'vue-nl2br'
import VueWaypoint from 'vue-waypoint'
import './plugins/filters'
import './plugins/validators'
import VueClipboard from 'vue-clipboard2'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import apiMethod from './api_call'
import { isArray, mergeWith } from 'lodash'
import VueMoment from 'vue-moment'
import moment from 'moment'
import { datetimePicker } from '../vendors/bootstrap-datetimepicker/bootstrap-datetimepicker'
import 'moment/dist/locale/fr'
import jQuery from 'jquery'
import './scss/new-design-system/index.scss'
import { FALLBACK_LOCALE } from '@/constants/language'
import { getNavigatorLocale, localeToLang } from '@/helpers/Language.helpers'
import posthog from 'posthog-js'
import { i18n, setI18nLocale } from '@/I18n.ts'
import { useErrorLogging } from '@/composables/UseErrorLogging.composables.ts'

/*global OverlayScrollbars*/

Vue.prototype.$posthog = posthog

const { logError } = useErrorLogging()

if (config.enable_survicate && config.survicate_key && config.survicate_key.length > 0) {
  const s = document.createElement('script')
  s.src = `https://survey.survicate.com/workspaces/${config.survicate_key}/web_surveys.js`
  s.async = true
  const e = document.getElementsByTagName('script')[0]
  e.parentNode.insertBefore(s, e)
}

if (config.enable_matomo) {
  window._mtm = window._mtm || []
  window._mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
  const s = window.document.createElement('script')
  s.src = config.matomo_host + '/js/container_' + config.matomo_container_id + '.js'
  s.async = true
  s.type = 'text/javascript'
  window.document.getElementsByTagName('head')[0].appendChild(s)
}

Vue.prototype.$language = {
  current: getNavigatorLocale().replace('-', '_'),
}

Vue.prototype.getCurrentLang = () => localeToLang(Vue.prototype.$language.current)

Vue.prototype._ = function (text) {
  // TODO: delete or refact when introducing i18n
  return Vue.prototype.$gettext(text, Vue.prototype.$language.current)
}

setI18nLocale(Vue.prototype.$language.current)

const copyNestedObjects = function (source, fields) {
  let target
  if (source === null) {
    return null
  } else if (Array.isArray(source)) {
    target = Array.from(source)
    for (let i = 0; i < target.length; i++) {
      target[i] = copyNestedObjects(target[i])
    }
  } else if (typeof source === 'object') {
    target = Object.assign({}, source)
    Object.keys(source).forEach((e) => {
      if (fields) {
        if (!fields.includes(e)) {
          delete target[e]
        } else {
          target[e] = copyNestedObjects(target[e])
        }
      } else {
        target[e] = copyNestedObjects(target[e])
      }
    })
  } else {
    return source
  }
  return target
}

window.$ = jQuery
window.jQuery = jQuery

window.moment = moment
Vue.use(VueMoment, { moment })
datetimePicker(jQuery, moment)

Vue.use(VueWaypoint)

Vue.use(LiquorTree)
Vue.use(BootstrapVue)

Vue.component('nl2br', Nl2br)
Vue.component('v-select', vSelect)
Vue.use(PrettyCheckbox)
Vue.use(OverlayScrollbarsPlugin)

// Your custom options
Vue.prototype.$http = Axios
Vue.prototype.$httpController = new AbortController()
Vue.prototype.$http.defaults.withCredentials = true
Vue.prototype.aciso_config = config
Vue.config.productionTip = false

Vue.use(FlagIcon)
Vue.use(VueLogger, {
  logLevel: config.log_level ? config.log_level : 'error',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
})

Vue.prototype.$domToImg = domtoimage
Vue.prototype.$jszip = jsZip
Vue.prototype.$FileSaver = FileSaver

Vue.use(GetTextPlugin, {
  availableLanguages: {
    en_US: 'English',
    fr_FR: 'Français',
  },
  defaultLanguage: FALLBACK_LOCALE,
  languageVmMixin: {
    computed: {
      currentKebabCase: function () {
        return this.current.toLowerCase().replace('_', '-')
      },
    },
  },
  translations: translations,
  silent: true,
})

Vue.use(VeeValidate, {
  classes: true,
  events: 'blur',
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid',
  },
  dictionary: {
    fr: {
      attributes: fields_fr,
    },
    en: {
      attributes: fields_en,
    },
  },
})

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err, vm, info)
}
if (config.enable_sentry) {
  Sentry.init(sentry_init_param)
}

Vue.prototype.copyNestedObjects = function (obj, fields) {
  return copyNestedObjects(obj, fields)
}

Vue.prototype.confirm = function (
  message,
  ok = null,
  ko = null,
  force = false,
  additional_delete = null,
  okv = 'outline-primary',
  title,
  hide_header = true,
  header_class = null
) {
  const _this = this
  if (typeof message == 'undefined') {
    message = this._('Are you sure?')
  }

  if (force) {
    return new Promise((resolve) => resolve())
  }
  if (ok === null) {
    ok = this._('Yes')
  }
  if (ko === null) {
    ko = this._('No')
  }
  if (title === null) {
    title = ''
  }
  if (!additional_delete) {
    return new Promise((resolve, reject) => {
      this.$bvModal
        .msgBoxConfirm(message, {
          noCloseOnEsc: true,
          noCloseOnBackdrop: true,
          okTitle: ok,
          cancelTitle: ko,
          cancelVariant: 'light',
          okVariant: okv,
          title: title,
          hideHeaderClose: hide_header,
          headerClass: header_class,
        })
        .then((ok) => {
          if (ok) {
            resolve()
          } else {
            reject(ok)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  } else {
    return new Promise((resolve, reject) => {
      const h = this.$createElement
      const titleVNode = h('div', { domProps: { innerText: message } })
      const content = []
      content.push(h('p', { class: ['text-center'] }, [_this._('Delete associated objects ?')]))

      additional_delete.forEach((opt) => {
        const check = h(
          'b-form-checkbox',
          {
            props: {
              value: opt,
              'unchecked-value': 'false',
            },
            domProps: {
              value: _this.value,
            },
            on: {
              change: function (event) {
                _this.$set(_this.additional_delete_values, 'obj_parent_deleted', null)
                _this.$set(_this.additional_delete_values, 'to_del', {})
                _this.$set(_this.additional_delete_values.to_del, opt, { objs: [], delete: event })
                _this.$emit('input:adds_del', event)
              },
            },
          },
          [opt]
        )
        content.push(check)
      })

      const messageVNode = h('div', { class: ['foobar'] }, content)
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: [titleVNode],
          noCloseOnEsc: true,
          noCloseOnBackdrop: true,
          okTitle: ok,
          cancelTitle: ko,
          cancelVariant: 'light',
          okVariant: 'outline-primary',
          hideHeaderClose: hide_header,
        })
        .then((ok) => {
          if (ok) {
            resolve()
          } else {
            reject()
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

Vue.prototype.msg_confirm = function (message, ok = null, force = false) {
  if (typeof message == 'undefined') {
    return
  }
  if (force) {
    return new Promise((resolve) => resolve())
  }
  if (ok === null) {
    ok = this._('Yes')
  }
  return new Promise((resolve, reject) => {
    this.$bvModal
      .msgBoxOk(message, {
        noCloseOnEsc: true,
        noCloseOnBackdrop: true,
        okTitle: ok,
        cancelVariant: 'light',
        okVariant: 'outline-primary',
      })
      .then((ok) => {
        if (ok) {
          resolve()
        } else {
          reject()
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

Vue.prototype.addAlpha = function (color, opacity = 0.5) {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}

Vue.prototype.rgb2Hex = function (rgb_str) {
  let a = rgb_str.split('(')[1].split(')')[0]
  a = a.split(',')
  const b = a.map(function (x) {
    //For each array element
    x = parseInt(x).toString(16) //Convert to a base16 string
    return x.length == 1 ? '0' + x : x //Add zero if we get only one character
  })
  return '#' + b.join('')
}

Vue.prototype.closestToZero = function (numbers) {
  if (!numbers.length) {
    return 0
  }
  let closest = 0
  for (const number of numbers) {
    if (closest === 0) {
      closest = number
    } else if (number > 0 && number <= Math.abs(closest)) {
      closest = number
    } else if (number < 0 && -number < Math.abs(closest)) {
      closest = number
    }
  }
  return closest
}

Vue.use(VueToastify, {
  theme: 'light',
  position: 'bottom-center',
  duration: 5000,
  errorDuration: 6000,
  successDuration: 2000,
  warningInfoDuration: 3000,
  customNotifications: {
    neutral: {
      type: 'neutral',
    },
  },
})

Vue.use(VueClipboard)

const dict = {
  custom: {
    link: {
      url: Vue.prototype._('Invalid URL'),
    },
  },
}
Validator.localize('en', dict)

Vue.prototype.$toast = {
  alert: (msg) => {
    Vue.prototype.$vToastify.error(msg, Vue.prototype._('Error') || 'Error')
  },
  warning: (msg) => {
    Vue.prototype.$vToastify.warning(msg, Vue.prototype._('Warning') || 'Warning')
  },
  warn: (msg) => {
    Vue.prototype.$vToastify.warning(msg, Vue.prototype._('Warning') || 'Warning')
  },
  info: (msg) => {
    Vue.prototype.$vToastify.info(msg, Vue.prototype._('Info') || 'Info')
  },
  success: (msg) => {
    Vue.prototype.$vToastify.success(msg, Vue.prototype._('Success') || 'Success')
  },
}

Vue.directive('click-outside', {
  bind: function (el, binding) {
    // Provided expression must evaluate to a function.
    if (typeof binding.value !== 'function') {
      //
    }
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = handler

    // add Event Listeners
    document.addEventListener('click', handler)
  },

  unbind: function (el) {
    // Remove Event Listeners
    document.removeEventListener('click', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  },
})

Vue.prototype.apiError = function (error, set_unloading = true, msg = null, warn = false) {
  this.$log.error(error)
  if (set_unloading) {
    store.commit('unloading')
  }
  if (error && error.response && error.response.status === 403) {
    this.$toast.alert(this._('Your session has expired.'))
    return
  }
  if (msg) {
    if (warn) {
      this.$toast.warn(msg)
    } else {
      this.$toast.alert(msg)
    }
  } else {
    this.$toast.alert(this._('Data cannot be loaded at the moment.'))
  }
}

Vue.prototype.$api = apiMethod

/**
 * Cancel ongoing requests by creating a new AbortController instance
 * and assigning it to the Vue instance.
 */
Vue.prototype.cancelRequests = function () {
  Vue.prototype.$httpController.abort()
  Vue.prototype.$httpController = new AbortController()
}

Vue.prototype.scaleIconIndicator = function (v, scale = null) {
  let scale_ = scale
  if (!scale_) {
    scale_ = this.scale
  }
  if (!scale_ || !scale_.indicator_levels || v === null) {
    return null
  }
  for (let i = 0; i < scale_.indicator_levels.length; i++) {
    if (i < scale_.indicator_levels.length - 1) {
      if (v >= scale_.indicator_levels[i].value && v < scale_.indicator_levels[i + 1].value) {
        return scale_.indicator_levels[i].icon
      }
    } else {
      if (v >= scale_.indicator_levels[i].value && v <= 100) {
        return scale_.indicator_levels[i].icon
      }
    }
  }
  return null
}

Vue.prototype.scaleLevelIndicator = function (v, scale = null) {
  let scale_ = scale
  if (!scale_) {
    scale_ = this.scale
  }
  if (!scale_ || !scale_.indicator_levels || v === null) {
    return null
  }
  if (scale_.type === 'PRIORITY') {
    for (const indicatorLevel of scale_.indicator_levels) {
      if (v === indicatorLevel.value) {
        return indicatorLevel
      }
    }
    // If no match is found, return the same level but with no color
    return null
  } else {
    for (let i = 0; i < scale_.indicator_levels.length; i++) {
      if (i < scale_.indicator_levels.length - 1) {
        if (v >= scale_.indicator_levels[i].value && v < scale_.indicator_levels[i + 1].value) {
          return scale_.indicator_levels[i]
        }
      } else {
        if (v >= scale_.indicator_levels[i].value && v <= 100) {
          return scale_.indicator_levels[i]
        }
      }
    }
  }

  return null
}

Vue.prototype.evalScaleColor = function (v, scale) {
  if (!scale || !scale.eval_levels) {
    return '#eee'
  }
  for (const evalLevel of scale.eval_levels) {
    if (v >= evalLevel.low_value && v <= evalLevel.high_value) {
      return evalLevel.color
    }
  }
  return '#eee'
}

Vue.prototype.evalScaleName = function (v, scale) {
  if (!scale || !scale.eval_levels) {
    return ''
  }
  for (const evalLevel of scale.eval_levels) {
    if (v >= evalLevel.low_value && v <= evalLevel.high_value) {
      return evalLevel.name
    }
  }
  return ''
}

Vue.prototype.escape_html_chars = function (text) {
  if (!text) {
    return text
  }
  return text.replace(/[<>\&\"\']/g, function (c) {
    return '&#' + c.charCodeAt(0) + ';'
  })
}

Vue.prototype.download_file = function (url) {
  this.$http({
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  })
    .then((response) => {
      if (response.data && response.data.ok && response.data.ok === false) {
        throw new Error()
      }
      let filename = response.headers['content-disposition']
      if (!filename || filename === '') {
        filename = 'no_filename'
      } else {
        filename = filename.replace('attachment; filename=', '')
      }
      if (filename[0] === '"') {
        const from = 1
        filename = filename.substring(from, from + filename.length - 2)
      }
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
    .catch((err) => {
      logError(err)
      this.$toast.alert(this._('You cannot download this file'))
      throw Error(err)
    })
}

Vue.prototype.wrap_words = function (label, limit = 30, truncate_limit = 60) {
  const words = label.split(' ')
  const aux = []
  let concat = []
  for (const word of words) {
    concat.push(word)
    const join = concat.join(' ')
    if (join.length > truncate_limit) {
      aux.push(join + '...')
      concat = []
      break
    }
    if (join.length > limit) {
      aux.push(join)
      concat = []
    }
  }

  if (concat.length) {
    aux.push(concat.join(' ').trim())
  }
  return aux
}

Vue.prototype.roundedScore = function (obj) {
  if (!obj) {
    return '-'
  }
  const score = obj.score
  if (score !== null && typeof score !== 'undefined' && !isNaN(score)) {
    return Math.round(score).toFixed(0)
  } else {
    return '-'
  }
}

Vue.prototype.highlight_md = function (md, kws) {
  let in_txt = String(md)
  if (kws && Array.isArray(kws) && kws.length > 0) {
    const regex = new RegExp(kws.join('|'), 'gi')
    const matches = in_txt.match(regex) || []
    if (matches.length) {
      for (const match of matches) {
        in_txt = in_txt.replace(new RegExp(match, 'g'), '<span class="md-hl">' + match + '</span>')
      }
    }
  }
  return in_txt
}
Vue.prototype.getWidth = function () {
  if ($('#main_content').width() && !isNaN($('#main_content').width())) {
    this.content_width = $('#main_content').width()
  } else {
    console.warn('NOT READY, DELAY')
    setTimeout(this.getWidth, 500)
  }
}

Vue.prototype.checkLabel = function (label_en, label_fr, type, questionnaire) {
  if (type === 'ITEM') {
    if ((label_en !== null && label_en.trim().length !== 0) || (label_fr !== null && label_fr.trim().length !== 0)) {
      return true
    } else {
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (const section of questionnaire.sections) {
          if (section.questions && section.questions.length > 0) {
            for (const question of section.questions) {
              if (question.items && question.items.length > 0) {
                question.items.forEach((val, index) => {
                  if (
                    !(val.label_en !== null && val.label_en.trim().length !== 0) &&
                    !(val.label_fr !== null && val.label_fr.trim().length !== 0)
                  ) {
                    question.items.splice(index, 1)
                    console.warn('GONNA SPLICE', index)
                  }
                })
              }
            }
          }
        }
      }
    }
  } else {
    if ((label_en !== null && label_en.trim().length !== 0) || (label_fr !== null && label_fr.trim().length !== 0)) {
      return true
    }
  }
}

Vue.prototype.checkIdentifier = function (identifier, type, questionnaire, item_id) {
  const reg = new RegExp(/^[a-zA-Z0-9_.\-]{1,10}$/g)
  if (reg.test(identifier) === false) {
    return false
  }

  if (identifier && type && questionnaire) {
    if (type === 'SECTION') {
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (const section of questionnaire.sections) {
          if (section.id === item_id) {
            continue
          }
          if (section.identifier.toString() === identifier.toString()) {
            return false
          }
        }
      }
      return true
    } else if (type === 'QUESTION') {
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (const section of questionnaire.sections) {
          if (section.questions && section.questions.length > 0) {
            for (const question of section.questions) {
              if (question.id === item_id) {
                continue
              }
              if (question.identifier.toString() === identifier.toString()) {
                return false
              }
            }
          }
        }
      }
      return true
    }
  }
  return false
}

Vue.prototype.generateIdentifier = function (type, questionnaire) {
  if (type && questionnaire) {
    if (type === 'SECTION') {
      const identifiers_array = [{ id: -1, val: 0, prefix: 'S' }]
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (const section of questionnaire.sections) {
          let numbers = []
          let prefix = []
          const identifier = section.identifier
          if (identifier.match(/[\d\.]+/g)) {
            numbers = identifier.match(/[\d\.]+/g).map((i) => parseInt(i))
          }
          if (identifier.match(/[\D\.]+/g)) {
            prefix = identifier.match(/[\D\.]+/g)
          }
          const new_identifier = {
            id: section.id,
            val: numbers && numbers.some((e) => e.val !== null) ? Math.max(...numbers) : 0,
            prefix: prefix[0] ? prefix[0] : '',
          }
          identifiers_array.push(new_identifier)
        }
      }
      const max_identifier = Math.max(...identifiers_array.map((id) => id.val)) + 1
      const max_prefix = identifiers_array.reduce((a, obj) => (a.val > obj.val ? a : obj)).prefix
      return max_prefix + max_identifier
    } else if (type === 'QUESTION') {
      const identifiers_array = [{ id: -1, val: 0, prefix: 'Q' }]
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (const section of questionnaire.sections) {
          if (section.questions && section.questions.length > 0) {
            for (const question of section.questions) {
              const identifier = question.identifier
              let numbers = []
              let prefix = []
              if (identifier.match(/[\d\.]+/g)) {
                numbers = identifier.match(/[\d\.]+/g).map((i) => parseInt(i))
              }
              if (identifier.match(/[\D\.]+/g)) {
                prefix = identifier.match(/[\D\.]+/g)
              }

              const new_identifier = {
                id: question.id,
                val: numbers && numbers.some((e) => e.val !== null) ? Math.max(...numbers) : 0,
                prefix: prefix[0] ? prefix[0] : '',
              }
              identifiers_array.push(new_identifier)
            }
          }
        }
      }
      const max_identifier = Math.max(...identifiers_array.map((id) => id.val)) + 1
      const max_prefix = identifiers_array.reduce((a, obj) => (a.val > obj.val ? a : obj)).prefix
      return max_prefix + max_identifier
    }
  }
}

function __custom_merge(objValue, srcValue) {
  if (isArray(objValue) && isArray(srcValue)) {
    return srcValue
  }
}

Vue.prototype.__merge = function (obj1, obj2) {
  return mergeWith(obj1, obj2, __custom_merge)
}

void store.dispatch('init')

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'far fa-arrow-up',
    down: 'far fa-arrow-down',
    right: 'far fa-arrow-right',
    previous: 'far fa-chevron-left',
    next: 'far fa-chevron-right',
    today: 'far fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
})

if (config.apm_enabled) {
  Vue.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: config.apm_service_name,
      serviceVersion: config.apm_service_version,
      serverUrl: config.apm_url,
      environment: config.apm_environment,
      logLevel: config.apm_log_level,
      breakdownMetrics: config.apm_breakdown_metrics,
      distributedTracingOrigins: [
        new RegExp('(https?://)?' + config.url),
        new RegExp('(https?://)?' + config.api_v2_url),
      ],
    },
  })
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
document.addEventListener('DOMContentLoaded', function () {
  //The first argument are the elements to which the plugin shall be initialized
  //The second argument has to be at least a empty object or a object with your desired options
  Vue.prototype.$os_body = OverlayScrollbars(document.querySelectorAll('body'), {
    scrollbars: { autoHide: 'scroll', autoHideDelay: 400 },
  })
  $('body').append($('.d3-tooltip'))
  $('body').append($('.tui-tooltip'))
})

import { _, isAuthorized, lazyLoadView, piloteAndAuditor } from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'

export const securityBaseRoutes: RouteConfig = {
  path: '/security_base',
  name: 'security_base',
  component: () => lazyLoadView(import('@/contexts/security-base/SecurityBaseView.vue')),
  meta: {
    title: _('Security bases'),
    icon: 'shield-check',
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    doc_id: 'security_bases',
    feature_id: 'SECURITY_BASE',
  },
  children: [
    {
      path: 'diff/:id',
      name: 'measures_diff',
      component: () => lazyLoadView(import('@/contexts/security-base/SecurityBaseMeasuresDiffView.vue')),
      meta: {
        title: _('Measures differences'),
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'measures_diff',
      },
    },
  ],
}

import {
  _,
  isAuthorized,
  lazyLoadView,
  onlyRootAdmin,
  piloteAndAuditor,
  piloteGlobalOnly,
  piloteOnly,
  rootAdmin,
  staffPlusAndMore,
  supportOnly,
} from '@/router/routing'
import MainContent from '@/components/layout/main-content.vue'
import MainApp from '@/components/main-app.vue'
import store from '@/store'
import { recurringTasksCatalogRoutes } from '@/contexts/recurring-tasks/RecurringTasks.routes'
import { actionPlansCatalogRoutes } from '@/contexts/action-plans/ActionPlans.routes'
import type { Route, RouteConfig } from 'vue-router'

// Build tools mother route for wheel menu (also used in main-sidebar when isPublic is true => administrate menu)

export const adminRoutes: RouteConfig[] = [
  {
    path: '/admin',
    name: 'admin',
    component: MainApp,
    meta: {
      title: _('Administrate'),
      icon: 'tools',
      dummy: true,
      requires_role: piloteAndAuditor,
      is_authorized: (route: Route) => isAuthorized(route),
    },
    children: [
      {
        path: 'catalog',
        name: 'catalog',
        component: MainContent,
        meta: {
          title: _('Catalog'),
          icon: 'medkit',
          multi: true,
          dummy: true,
          requires_auth: true,
          requires_tenant: false,
          requires_role: piloteAndAuditor,
          is_authorized: (route: Route) => isAuthorized(route),
          doc_id: 'catalog',
          feature_id: 'CATALOG',
        },
        children: [
          {
            path: 'policies',
            name: 'catalog_policies',
            component: () => lazyLoadView(import('@/components/pages/catalog-policies.vue')),
            meta: {
              title: _('Policies'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_tenant: false,
              requires_role: piloteAndAuditor,
              doc_id: 'catalog',
              feature_id: 'CATALOG',
            },
            children: [
              {
                path: ':id',
                name: 'policy_version_catalog',
                component: () => lazyLoadView(import('@/contexts/policies/PolicyVersionView.vue')),
                meta: {
                  title: null,
                  requires_auth: true,
                  requires_tenant: false,
                  sidebar: false,
                  is_authorized: (route: Route) => isAuthorized(route),
                  requires_role: piloteAndAuditor,
                  doc_id: 'catalog_policy_details',
                },
                children: [
                  {
                    path: ':policy_id/diff/:ref',
                    name: 'policy_diff',
                    component: () => lazyLoadView(import('@/components/pages/policy-diff.vue')),
                    meta: {
                      title: _('Differences'),
                      requires_auth: true,
                      requires_tenant: false,
                      sidebar: false,
                      is_authorized: (route: Route) => isAuthorized(route),
                      requires_role: piloteAndAuditor,
                      doc_id: 'policy_diff',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'questionnaires',
            name: 'catalog_questionnaires',
            component: () => lazyLoadView(import('@/components/pages/catalog-questionnaires.vue')),
            meta: {
              title: _('Questionnaires'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_tenant: false,
              requires_role: piloteAndAuditor,
              doc_id: 'catalog',
              feature_id: 'CATALOG',
            },
            children: [
              {
                path: ':id',
                name: 'catalog_questionnaire',
                component: () => lazyLoadView(import('@/contexts/policies/PoliciesQuestionnaireView.vue')),
                meta: {
                  title: null,
                  is_authorized: (route: Route) => isAuthorized(route),
                  requires_auth: true,
                  requires_tenant: false,
                  requires_role: piloteAndAuditor,
                  doc_id: 'questionnaire',
                },
              },
            ],
          },
          {
            path: 'measures',
            name: 'blueprints',
            component: () => lazyLoadView(import('@/components/pages/solutions.vue')),
            meta: {
              title: _('Measures'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_tenant: false,
              requires_role: piloteAndAuditor,
              doc_id: 'catalog',
              feature_id: 'CATALOG',
            },
          },
          actionPlansCatalogRoutes,
          {
            path: 'metrics',
            name: 'metrics',
            component: () => lazyLoadView(import('@/components/pages/metrics-catalog.vue')),
            meta: {
              title: _('Metrics'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_tenant: false,
              requires_role: piloteAndAuditor,
              doc_id: 'catalog',
              feature_id: 'CATALOG',
            },
          },
          {
            path: 'indicators',
            name: 'indicators',
            component: () => lazyLoadView(import('@/components/pages/indicators-catalog.vue')),
            meta: {
              title: _('Indicators'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_tenant: false,
              requires_role: piloteAndAuditor,
              doc_id: 'catalog',
              feature_id: 'CATALOG',
            },
          },
          recurringTasksCatalogRoutes,
          {
            path: 'connectors',
            name: 'connectors',
            component: () => lazyLoadView(import('@/components/pages/catalog-integrations.vue')),
            meta: {
              title: _('Connectors'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_tenant: false,
              requires_role: piloteAndAuditor,
              doc_id: 'catalog',
              feature_id: 'CATALOG',
            },
          },
          {
            path: 'issues',
            name: 'issues',
            component: () => lazyLoadView(import('@/components/pages/issue-catalog.vue')),
            meta: {
              title: _('Issues'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_no_tenant: true,
              requires_role: staffPlusAndMore,
              doc_id: 'catalog',
              feature_id: 'CATALOG',
            },
          },
          {
            path: 'mitigation',
            name: 'mitigations',
            component: () => lazyLoadView(import('@/components/pages/mitigation-catalog.vue')),
            meta: {
              title: _('Mitre Mitigations'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_no_tenant: true,
              requires_role: onlyRootAdmin,
              doc_id: 'catalog',
              feature_id: 'CATALOG',
            },
          },
        ],
      },
      {
        path: 'customers',
        name: 'customer',
        component: () => lazyLoadView(import('@/components/pages/customers.vue')),
        meta: {
          title: _('Customers'),
          icon: 'user-circle',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: staffPlusAndMore,
          requires_auth: true,
          requires_no_tenant: true,
        },
      },
      {
        path: 'instance',
        name: 'instance',
        component: () => lazyLoadView(import('@/components/pages/instance.vue')),
        meta: {
          title: _('Instance'),
          icon: 'server',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: rootAdmin,
          requires_auth: true,
          requires_no_tenant: true,
          for_partner: true,
        },
      },
      {
        path: 'auth_config',
        name: 'auth_config',
        component: () => lazyLoadView(import('@/components/pages/auth-config.vue')),
        meta: {
          title: _('Authentication'),
          icon: 'fingerprint',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteGlobalOnly,
          requires_auth: true,
          requires_tenant: false,
          doc_id: 'auth_config',
          feature_id: 'SETTINGS',
        },
      },
      {
        path: 'users',
        name: 'user',
        component: () => lazyLoadView(import('@/components/pages/users.vue')),
        meta: {
          title: _('Users'),
          icon: 'user',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteOnly.concat(supportOnly),
          requires_auth: true,
          requires_tenant: false,
          doc_id: 'user',
          feature_id: 'USER_GROUP',
        },
        children: [
          {
            path: ':id/objects',
            name: 'user_objects',
            component: () => lazyLoadView(import('@/components/pages/user-objects.vue')),
            meta: {
              title: null,
              icon: 'boxes',
              is_authorized: (route: Route) => isAuthorized(route),
              requires_role: piloteOnly,
              requires_auth: true,
              requires_tenant: true,
              doc_id: 'user_objects',
            },
          },
        ],
      },
      {
        path: 'group',
        name: 'group',
        component: () => lazyLoadView(import('@/components/pages/groups.vue')),
        meta: {
          title: _('Groups'),
          icon: 'users',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteOnly,
          requires_auth: true,
          requires_tenant: true,
          doc_id: 'group',
          feature_id: 'USER_GROUP',
        },
        children: [
          {
            path: ':id/objects',
            name: 'group_objects',
            component: () => lazyLoadView(import('@/components/pages/user-objects.vue')),
            meta: {
              title: null,
              icon: 'boxes',
              is_authorized: (route: Route) => isAuthorized(route),
              requires_role: piloteGlobalOnly,
              requires_auth: true,
              requires_tenant: true,
              doc_id: 'group_objects',
            },
          },
        ],
      },
      {
        path: 'functions',
        name: 'function',
        component: () => lazyLoadView(import('@/components/pages/functions.vue')),
        meta: {
          title: _('Functions'),
          icon: 'user-tag',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteGlobalOnly,
          requires_auth: true,
          requires_tenant: true,
          doc_id: 'function',
          feature_id: 'SETTINGS',
        },
      },
      {
        path: 'perimeter_properties',
        name: 'perimeter_properties',
        component: () => lazyLoadView(import('@/components/pages/perimeter-properties.vue')),
        meta: {
          title: _('Properties'),
          icon: 'id-card',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteGlobalOnly,
          requires_auth: true,
          requires_tenant: true,
          doc_id: 'perimeter_property',
          feature_id: 'SETTINGS',
        },
      },
      {
        path: 'contacts',
        name: 'contact',
        component: () => lazyLoadView(import('@/components/pages/contacts.vue')),
        meta: {
          title: _('Contacts'),
          icon: 'address-book',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteOnly.concat(supportOnly),
          requires_auth: true,
          requires_tenant: true,
          doc_id: 'contact',
          feature_id: 'SETTINGS',
        },
      },
      {
        path: 'scales',
        name: 'scales',
        component: MainContent,
        meta: {
          title: _('Scales'),
          icon: 'signal',
          multi: true,
          dummy: true,
          requires_auth: true,
          requires_tenant: false,
          requires_role: piloteGlobalOnly,
          is_authorized: (route: Route) => isAuthorized(route),
          doc_id: 'scales',
          feature_id: 'SETTINGS',
        },
        children: [
          {
            path: 'indicator',
            name: 'indicator_scales',
            component: () => lazyLoadView(import('@/components/pages/scales.vue')),
            meta: {
              title: _('Indicator'),
              icon: 'signal',
              is_authorized: (route: Route) => isAuthorized(route),
              requires_role: piloteGlobalOnly,
              requires_auth: true,
              requires_tenant: false,
              doc_id: 'scales',
              feature_id: 'SETTINGS',
            },
          },
          {
            path: 'eval',
            name: 'eval_scales',
            component: () => lazyLoadView(import('@/components/pages/scales.vue')),
            meta: {
              title: _('Evaluation'),
              icon: 'signal',
              is_authorized: (route: Route) => isAuthorized(route),
              requires_role: piloteGlobalOnly,
              requires_auth: true,
              requires_tenant: false,
              doc_id: 'scales',
              feature_id: 'SETTINGS',
            },
          },
          {
            path: 'risk',
            name: 'risk_scales',
            component: () => lazyLoadView(import('@/components/pages/scales.vue')),
            meta: {
              title: _('Risk'),
              icon: 'signal',
              is_authorized: (route: Route) => isAuthorized(route),
              requires_role: piloteGlobalOnly,
              requires_auth: true,
              requires_tenant: false,
              doc_id: 'scales',
              feature_id: 'SETTINGS',
            },
          },
          {
            path: 'perimeter',
            name: 'perimeter_scales',
            component: () => lazyLoadView(import('@/components/pages/scales.vue')),
            meta: {
              title: _('Perimeter'),
              icon: 'signal',
              is_authorized: (route: Route) => isAuthorized(route),
              requires_role: piloteGlobalOnly,
              requires_auth: true,
              requires_tenant: false,
              doc_id: 'scales',
              feature_id: 'SETTINGS',
            },
          },
          {
            path: 'priority',
            name: 'priority_scales',
            component: () => lazyLoadView(import('@/components/pages/scales.vue')),
            meta: {
              title: _('Priority'),
              icon: 'signal',
              is_authorized: (route: Route) => isAuthorized(route),
              requires_role: piloteGlobalOnly,
              requires_auth: true,
              requires_tenant: false,
              doc_id: 'scales',
              feature_id: 'SETTINGS',
            },
          },
          {
            path: 'weather',
            name: 'weather_scales',
            component: () => lazyLoadView(import('@/components/pages/scales.vue')),
            meta: {
              title: _('Weather'),
              icon: 'signal',
              is_authorized: (route: Route) => isAuthorized(route),
              requires_role: piloteGlobalOnly,
              requires_auth: true,
              requires_tenant: false,
              doc_id: 'scales',
              feature_id: 'SETTINGS',
            },
          },
          {
            path: 'business_impacts',
            name: 'business_impacts_scales',
            component: () => lazyLoadView(import('@/components/pages/scales.vue')),
            meta: {
              title: _('Business stakes'),
              icon: 'signal',
              is_authorized: (route: Route) => isAuthorized(route),
              requires_role: piloteGlobalOnly,
              requires_auth: true,
              requires_tenant: false,
              doc_id: 'scales',
              feature_id: 'SETTINGS',
            },
          },
        ],
      },
      {
        path: 'categories',
        name: 'category',
        component: () => lazyLoadView(import('@/components/pages/categories.vue')),
        meta: {
          title: _('Categories'),
          icon: 'briefcase',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: rootAdmin,
          requires_auth: true,
          requires_tenant: false,
          requires_no_tenant: true,
          doc_id: 'category',
        },
      },
      {
        path: 'labels',
        name: 'label',
        component: () => lazyLoadView(import('@/components/pages/labels.vue')),
        meta: {
          title: _('Labels'),
          icon: 'tags',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteOnly,
          requires_auth: true,
          requires_tenant: true,
          doc_id: 'label',
          feature_id: 'SETTINGS',
        },
      },
      {
        path: 'internal_indicators',
        name: 'internal_indicator',
        component: () => lazyLoadView(import('@/components/pages/internal-indicators.vue')),
        meta: {
          title: _('Internal indicators'),
          icon: 'chart-line',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteGlobalOnly,
          requires_auth: true,
          requires_tenant: true,
          doc_id: 'internal_indicator',
          feature_id: 'DASHBOARDS',
        },
      },
      {
        path: 'internal_metrics',
        name: 'internal_metric',
        component: () => lazyLoadView(import('@/components/pages/internal-metrics.vue')),
        meta: {
          title: _('Internal metrics'),
          icon: 'chart-scatter',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteGlobalOnly,
          requires_auth: true,
          requires_tenant: true,
          doc_id: 'internal_metrics',
          feature_id: 'DASHBOARDS',
        },
      },
      {
        path: 'public_api',
        name: 'public_api',
        component: () => lazyLoadView(import('@/components/pages/public-api.vue')),
        meta: {
          title: _('Public API'),
          icon: 'key',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteGlobalOnly,
          requires_auth: true,
          requires_tenant: true,
          hidden_for_fp_assessment: true,
          hidden_for_fe_student: true,
          doc_id: 'public_api',
          feature_id: 'INTEGRATION',
        },
      },
      {
        path: 'logs',
        name: 'log',
        component: () => lazyLoadView(import('@/components/pages/logs.vue')),
        meta: {
          title: _('Logs'),
          icon: 'shoe-prints',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteGlobalOnly,
          requires_auth: true,
          requires_tenant: false,
          doc_id: 'log',
        },
      },
      {
        path: 'import_reports',
        name: 'import_report',
        component: () => lazyLoadView(import('@/components/pages/imports.vue')),
        meta: {
          title: _('Reports'),
          icon: 'file-import',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteGlobalOnly,
          requires_auth: true,
          requires_tenant: false,
          doc_id: 'import_report',
        },
      },
      {
        path: 'preferences',
        name: 'preference',
        component: () => lazyLoadView(import('@/components/pages/preferences.vue')),
        meta: {
          title: _('Preferences'),
          icon: 'sliders-h',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteGlobalOnly,
          requires_auth: true,
          requires_tenant: true,
          doc_id: 'preference',
          feature_id: 'SETTINGS',
        },
      },
      {
        path: 'licenses',
        name: 'licenses',
        component: () => lazyLoadView(import('@/components/pages/licenses.vue')),
        meta: {
          title: _('Licenses'),
          icon: 'badge-check',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: store.getters.instance_type !== 'business' && store.getters.isPublic ? [] : piloteGlobalOnly,
          requires_auth: true,
          requires_tenant: true,
          doc_id: 'license',
          feature_id: 'SETTINGS',
        },
      },
      {
        path: 'customer_connectors',
        name: 'customer_connector',
        component: () => lazyLoadView(import('@/components/pages/customer-connectors.vue')),
        meta: {
          title: _('Connectors'),
          icon: 'puzzle-piece',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: piloteOnly,
          requires_auth: true,
          requires_tenant: true,
          hidden_for_fp_assessment: true,
          doc_id: 'engine',
          feature_id: 'INTEGRATION',
        },
        children: [
          {
            path: 'customer_connector/:connector_id/jobs',
            name: 'jobs',
            component: () => lazyLoadView(import('@/components/pages/connector-jobs.vue')),
            meta: {
              title: _('Integration jobs'),
              icon: 'briefcase',
              is_authorized: (route: Route) => isAuthorized(route),
              requires_role: piloteOnly,
              requires_auth: true,
              requires_tenant: true,
              hidden_for_fp_assessment: true,
              doc_id: 'engine_jobs',
            },
          },
        ],
      },
      {
        path: 'connectors',
        name: 'connector',
        component: () => lazyLoadView(import('@/components/pages/connectors.vue')),
        meta: {
          title: _('Connectors'),
          icon: 'tools',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: rootAdmin,
          requires_auth: true,
          requires_no_tenant: true,
          doc_id: 'connector',
        },
      },
      {
        path: 'workers',
        name: 'worker',
        component: () => lazyLoadView(import('@/components/pages/integration-workers.vue')),
        meta: {
          title: _('Integration workers'),
          icon: 'cogs',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: rootAdmin,
          requires_auth: true,
          requires_no_tenant: true,
          doc_id: 'worker',
        },
      },
      {
        path: 'properties',
        name: 'properties',
        component: () => lazyLoadView(import('@/components/pages/metadata-properties.vue')),
        meta: {
          title: _('Properties'),
          icon: 'file-chart-line',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: rootAdmin,
          requires_auth: true,
          requires_no_tenant: true,
          doc_id: 'keywords',
        },
        children: [],
      },
      {
        path: 'risk-analysis-catalog',
        name: 'risk-analysis-catalog',
        component: MainContent,
        meta: {
          title: _('Risks'),
          icon: 'radiation-alt',
          multi: true,
          dummy: true,
          requires_auth: true,
          requires_no_tenant: true,
          requires_role: staffPlusAndMore,
          is_authorized: (route: Route) => isAuthorized(route),
          doc_id: 'risk-catalog',
        },
        children: [
          {
            path: 'risks',
            name: 'risks_catalog',
            component: () => lazyLoadView(import('@/contexts/risks/RisksAdminCatalogView.vue')),
            meta: {
              title: _('Risks'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_no_tenant: true,
              requires_role: staffPlusAndMore,
              doc_id: 'risk-catalog',
            },
          },
          {
            path: 'business-value',
            name: 'business_value_catalog',
            component: () => lazyLoadView(import('@/contexts/risks/catalog/CatalogBusinessValues.vue')),
            meta: {
              title: _('Business values'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_no_tenant: true,
              requires_role: staffPlusAndMore,
              doc_id: 'business-value-catalog',
            },
          },
          {
            path: 'supporting-asset',
            name: 'supporting_asset_catalog',
            component: () => lazyLoadView(import('@/contexts/risks/catalog/CatalogSupportingAssets.vue')),
            meta: {
              title: _('Supporting assets'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_no_tenant: true,
              requires_role: staffPlusAndMore,
              doc_id: 'supporting-asset-catalog',
            },
          },
          {
            path: 'threat',
            name: 'threat_catalog',
            component: () => lazyLoadView(import('@/contexts/risks/catalog/CatalogThreats.vue')),
            meta: {
              title: _('Threats'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_no_tenant: true,
              requires_role: staffPlusAndMore,
              doc_id: 'threat-catalog',
            },
          },
        ],
      },
      {
        path: 'catalog_sync',
        name: 'catalog_sync',
        component: () => lazyLoadView(import('@/components/pages/catalog-sync.vue')),
        meta: {
          title: _('Sync configuration'),
          icon: 'check',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: rootAdmin,
          requires_auth: true,
          requires_no_tenant: true,
          doc_id: 'catalog_sync',
          feature_id: 'SETTINGS',
        },
      },
      {
        path: 'login-message',
        name: 'login-message',
        component: () => lazyLoadView(import('@/components/pages/login-message.vue')),
        meta: {
          title: _('Login message'),
          icon: 'comment-alt-edit',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_role: rootAdmin,
          requires_auth: true,
          requires_no_tenant: true,
        },
      },
    ],
  },
]

import MainContent from '@/components/layout/main-content.vue'
import { _, isAuthorized, isGlobalPiloteOrAuditor, lazyLoadView, piloteAndAuditor, setNavL2 } from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'

export const performanceRoutes: RouteConfig = {
  path: '/org2',
  name: 'organization2',
  component: MainContent,
  meta: {
    title: _('Performance'),
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    is_authorized: (route: Route) => isAuthorized(route),
  },
  children: [
    {
      path: '/perimeters',
      name: 'perimeters_scores',
      component: () => lazyLoadView(import('@/components/pages/perimeters.vue')),
      meta: {
        title: _('Perimeters'),
        icon: 'layer-group',
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'perimeters',
      },
      children: [],
    },
    {
      path: '/benchmark',
      name: 'dashboard_scores',
      component: () => lazyLoadView(import('@/components/pages/scores.vue')),
      meta: {
        title: _('Benchmark'),
        icon: 'chart-line',
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: isGlobalPiloteOrAuditor,
        doc_id: 'benchmark',
        feature_id: 'POLICIES',
      },
      children: [],
    },
    {
      path: '/reporting',
      name: 'reporting',
      component: MainContent,
      meta: {
        i18nTitle: 'dashboards.menu.title',
        icon: 'tachometer-fast',
        multi: true,
        dummy: true,
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        is_authorized: (route: Route) => isAuthorized(route),
        doc_id: 'dashboards',
        feature_id: 'DASHBOARDS',
      },
      children: [
        {
          path: 'dashboards',
          name: 'dashboard',
          component: () => lazyLoadView(import('@/components/pages/dashboards.vue')),
          beforeEnter: setNavL2,
          meta: {
            i18nTitle: 'dashboards.menu.title',
            icon: 'tachometer-fast',
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            hide_in_breadcrumb: true,
            requires_role: piloteAndAuditor,
            doc_id: 'dashboards',
            feature_id: 'DASHBOARDS',
          },
          children: [
            {
              path: ':id/builder',
              name: 'dashboard_builder',
              component: () => lazyLoadView(import('@/components/pages/dashboard-builder.vue')),
              meta: {
                title: null,
                is_authorized: (route: Route) => isAuthorized(route),
                requires_auth: true,
                requires_tenant: true,
                requires_role: piloteAndAuditor,
                doc_id: 'dashboard_builder',
                feature_id: 'DASHBOARDS',
              },
            },
            {
              path: ':id/viewer',
              name: 'dashboard_viewer',
              component: () => lazyLoadView(import('@/components/pages/dashboard-viewer.vue')),
              meta: {
                title: null,
                is_authorized: (route: Route) => isAuthorized(route),
                requires_tenant: true,
                requires_auth: true,
                requires_role: piloteAndAuditor,
                doc_id: 'dashboard_viewer',
                feature_id: 'DASHBOARDS',
              },
            },
          ],
        },
        {
          path: 'metrics',
          name: 'metric',
          component: () => lazyLoadView(import('@/components/pages/metrics.vue')),
          beforeEnter: setNavL2,
          meta: {
            i18nTitle: 'dashboards.menu.metrics',
            icon: 'chart-scatter',
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'metric',
            feature_id: 'DASHBOARDS',
          },
        },
        {
          path: 'indicators',
          name: 'indicator',
          component: () => lazyLoadView(import('@/components/pages/indicators.vue')),
          beforeEnter: setNavL2,
          meta: {
            page_title: 'Indicators',
            i18nTitle: 'dashboards.menu.indicators',
            icon: 'chart-line',
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'indicator',
            feature_id: 'DASHBOARDS',
          },
        },
      ],
    },
  ],
}

<template>
  <aciso-form
    :ref="modal_id"
    :hide_footer="hide_footer"
    :hide_header="hide_header"
    modal
    :panel_id="modal_id"
    :panel_title="modal_title"
    :read_only="current_operation === 'view'"
    @cancel="cancelled"
    @submit="submit()"
  >
    <form :data-vv-scope="scope">
      <b-tabs
        v-model="selected_tab"
        card
        class="tabs-l1"
        pills
        @changed="selected_tab = 0"
      >
        <b-tab
          v-if="
            (current_obj.mfa_enabled === true && current_obj.mfa_activated === true) ||
            current_obj.mfa_activated === false
          "
        >
          <template slot="title">
            {{ _('Information') }}

            <span
              v-if="hasError(['email', 'firstname', 'lastname', 'auth_type', 'mfa_mode', 'lang', 'roles'])"
              v-b-tooltip
              class="badge bg-warning missing_notif"
              :title="_('Validation error')"
              ><i class="far fa-exclamation-triangle"
            /></span>
          </template>
          <b-row class="mb-3">
            <b-col>
              <h6>
                <translate ref="roles_">Roles</translate>
              </h6>
              <b-badge
                v-for="(r, index) in current_obj.roles"
                :key="index"
                :variant="r.color || 'primary'"
              >
                <span v-if="r.name !== 'user'">{{ r.name }}</span>
              </b-badge>
            </b-col>
            <b-col v-if="$store.getters.isPiloteOrAuditor">
              <div>
                <h6>
                  <translate ref="perimeters_">Perimeter/grouping</translate>
                </h6>
                <b-badge
                  v-if="current_obj.local_perimeter"
                  variant="tertiary"
                >
                  {{ current_obj.local_perimeter.name }}
                </b-badge>
                <b-badge
                  v-if="current_obj.local_tag"
                  variant="primary"
                >
                  {{ current_obj.local_tag.tag }}
                </b-badge>
              </div>
            </b-col>
            <b-col>
              <div>
                <h6>
                  <translate ref="groups_">Groups</translate>
                </h6>
                <b-badge
                  v-for="group in current_obj.groups"
                  :key="group.id"
                  variant="primary"
                  >{{ group.name }}</b-badge
                >
              </div>
            </b-col>
          </b-row>
          <form-field
            v-model="current_obj.email"
            :disabled="true"
            :error="errors.first(scope + '.email')"
            :label="_('Email')"
            name="email"
            type="text"
          />
          <form-field
            v-model="current_obj.firstname"
            v-validate="'required|max:128'"
            data-testid="first-name"
            :error="errors.first(scope + '.firstname')"
            :label="_('Firstname') + ' *'"
            name="firstname"
            type="text"
            @input="updateInitials"
          />
          <form-field
            v-model="current_obj.lastname"
            v-validate="'required|max:128'"
            data-testid="last-name"
            :error="errors.first(scope + '.lastname')"
            :label="_('Lastname')"
            name="lastname"
            type="text"
            @input="updateInitials"
          />
          <form-field
            v-model="current_obj.initials"
            v-validate="'max:3'"
            data-testid="initials"
            :error="errors.first(scope + '.initials')"
            :label="_('Initials')"
            name="initials"
            type="text"
          />
          <form-field
            v-model="current_obj.job_title"
            v-validate="'max:128'"
            data-testid="title"
            :error="errors.first(scope + '.title')"
            :label="_('Title')"
            name="title"
            type="text"
          />
          <b-row>
            <b-col cols="6">
              <form-field-checkbox
                v-model="current_obj.weekly_digest"
                data-testid="checkbox-digest"
                :disabled="current_operation === 'view'"
                name="weekly_digest"
                :title="_('Receive weekly report')"
              />
            </b-col>
            <b-col cols="6">
              <form-field-checkbox
                v-model="current_obj.daily_notifications"
                data-testid="checkbox-user-daily-digest"
                :disabled="current_operation === 'view'"
                name="daily_digest"
                :title="_('Receive notification')"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          v-if="
            current_obj.auth_type === 'PASSWORD' &&
            (current_obj.mfa_mode === 'USER_CHOICE' || current_obj.mfa_mode === 'ENFORCED')
          "
        >
          <template slot="title">
            {{ _('Strong authentication') }}
          </template>
          <b-row v-if="current_obj.mfa_mode === 'USER_CHOICE'">
            <span class="mr-2 label-toggle label-mfa"
              ><strong><translate>Enable strong authentication</translate></strong></span
            >
            <form-field-toggle
              id="mfa_enabled"
              v-model="current_obj.mfa_activated"
              class="mt-2 ml-1"
              data-testid="toggle-mfa"
              name="mfa_enabled"
              :width="40"
              @input="enabledMfa(current_obj.mfa_activated)"
            />
          </b-row>
          <b-row v-if="current_obj.mfa_activated === true">
            <span class="mr-2 label-toggle label-mfa"
              ><strong
                ><translate
                  >Use a TOTP (RFC 6238) compatible application like FreeOTP or Google Authenticator</translate
                ></strong
              ></span
            >
          </b-row>
          <b-row class="mfa-verified">
            <strong
              v-if="
                current_obj.mfa_enabled === true &&
                current_obj.mfa_secret !== null &&
                current_obj.mfa_activated === true &&
                (current_obj.mfa_mode === 'ENFORCED' || current_obj.mfa_mode === 'USER_CHOICE')
              "
              ><i class="far fa-check-circle green" />&nbsp;<translate>Your secret has been verified</translate></strong
            ></b-row
          >
          <b-row class="mfa">
            <button
              v-if="current_obj.mfa_activated === true && current_obj.mfa_secret !== null"
              class="btn btn-primary excube-btn mfa-button"
              data-testid="btn-show-hide-secret"
              type="button"
              @click="showSecret()"
            >
              {{ show_secret_label }}
            </button>
          </b-row>
          <b-row
            v-if="
              show_secret === true &&
              current_obj.mfa_enabled === false &&
              current_obj.mfa_secret !== null &&
              current_obj.mfa_activated === true
            "
            class="mfa-text"
          >
            <translate>Scan the QR code or enter the secret manually in your application</translate>
          </b-row>
          <b-row v-if="show_secret === true && current_obj.mfa_secret !== null && current_obj.mfa_activated === true">
            <b-col :cols="4">
              <qrcode-vue
                class="mt-5 mb-5"
                level="L"
                :size="200"
                :value="current_obj.qr_code"
              />
            </b-col>
            <b-col
              class="mfa-secret"
              :cols="8"
            >
              <b-row>
                <div class="pre-mfa">
                  <strong><translate>MFA Secret :</translate></strong>
                </div>
                <pre>{{ current_obj.mfa_secret }}</pre>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="current_obj.mfa_activated === true">
            <button
              v-if="current_obj && current_obj.mfa_secret !== null"
              class="btn btn-outline-danger excube-btn"
              data-testid="btn-reset-secret"
              type="button"
              @click="resetSecret()"
            >
              <translate>Reset secret</translate>
            </button>
          </b-row>
          <b-row
            v-if="
              current_obj.mfa_secret !== null && current_obj.mfa_activated === true && current_obj.mfa_enabled === false
            "
            class="mfa"
          >
            <translate>You must validate your authentication code below to enable strong authentication</translate>
          </b-row>
          <b-row
            v-if="
              current_obj.mfa_activated === true && current_obj.mfa_enabled === false && current_obj.mfa_secret !== null
            "
          >
            <form-field
              v-model="authentication_code"
              v-validate="'required|length:6|numeric'"
              class="mfa"
              data-testid="check-secret"
              :error="errors.first(scope + '.authentication_code')"
              :label="_('Authentication code') + ' *'"
              name="authentication_code"
              type="number"
              @enter="checkSecret()"
            />
            <form-field
              v-show="false"
              v-model="current_obj.no_enter"
              :error="errors.first(scope + '.no_enter')"
              name="no_enter"
              type="text"
            />
          </b-row>
          <b-row
            v-if="
              current_obj.mfa_activated === true && current_obj.mfa_enabled === false && current_obj.mfa_secret !== null
            "
            @click="checkSecret()"
          >
            <button
              class="btn btn-primary excube-btn mfa"
              data-testid="btn-check-secret"
              type="button"
            >
              <translate>Check secret</translate>
            </button>
          </b-row>
        </b-tab>
      </b-tabs>
    </form>
  </aciso-form>
</template>

<script>
import FormField from '../ui/form-field.vue'
import EditMixin from '../mixins/edit-mixin'
import AcisoForm from '../layout/aciso-form.vue'
import FormFieldCheckbox from '../ui/form-field-checkbox.vue'
import FormFieldToggle from '@/components/ui/form-field-toggle.vue'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'user-profile',
  components: {
    FormFieldToggle,
    FormFieldCheckbox,
    AcisoForm,
    FormField,
    QrcodeVue,
  },
  mixins: [EditMixin],
  data: function () {
    return {
      current_obj: {},
      show_secret: false,
      show_secret_label: '',
      scope: 'profile-edit',
      hide_footer: false,
      hide_header: false,
      authentication_code: '',
      dashboard_options: [],
    }
  },
  computed: {
    modal_edit_title: function () {
      return this._('Edit profile')
    },
    modal_view_title: function () {
      return this._('View profile')
    },
  },
  methods: {
    enabledMfa(status) {
      this.$log.debug(status)
      this.$http
        .put('/user/generate_secret', { mfa_activated: status })
        .then((resp) => {
          if (resp.data.ok) {
            if (resp.data.mfa_activated === true) {
              this.$set(this.current_obj, 'mfa_secret', resp.data.secret)
              this.$set(this.current_obj, 'qr_code', resp.data.qr_code)
              this.current_obj.mfa_enabled = resp.data.mfa_enabled
              this.current_obj.mfa_activated = resp.data.mfa_activated
              if (resp.data.mfa_enabled === false) {
                this.hide_footer = true
                this.hide_header = true
                this.show_secret = true
                this.show_secret_label = this._('Hide secret')
                this.$toast.info(this._('Strong authentication has been activated. You must verify your secret'))
              } else if (resp.data.mfa_enabled === true) {
                this.hide_footer = false
                this.hide_header = false
                this.show_secret = false
                this.show_secret_label = this._('Show secret')
              }
            } else if (resp.data.mfa_activated === false) {
              this.hide_footer = false
              this.hide_header = false
            }
          } else {
            throw new Error()
          }
        })
        .catch((err) => {
          this.$log.debug(err)
          this.apiError()
        })
    },
    showSecret() {
      this.show_secret = !this.show_secret
      if (this.show_secret === false) {
        this.show_secret_label = this._('Show secret')
      } else {
        this.show_secret_label = this._('Hide secret')
      }
    },
    resetSecret() {
      this.confirm(this._('This will delete previous secret. Are you sure ?'), this._('Yes'), this._('Cancel')).then(
        () => {
          this.$http
            .put('/user/reset_secret', { id: this.current_obj.id })
            .then((resp) => {
              if (resp.data.ok) {
                this.$set(this.current_obj, 'mfa_secret', resp.data.secret)
                this.$set(this.current_obj, 'qr_code', resp.data.qr_code)
                this.current_obj.mfa_enabled = false
                this.hide_footer = true
                this.hide_header = true
                this.$toast.info(this._('MFA secret deleted'))
              } else {
                throw new Error()
              }
            })
            .catch((err) => {
              this.$log.debug(err)
              this.apiError()
            })
        }
      )
    },
    checkSecret() {
      this.$http
        .put('/user/check_secret', { id: this.current_obj.id, authentication_code: this.authentication_code })
        .then((resp) => {
          if (resp.data.ok) {
            this.current_obj.mfa_enabled = true
            this.hide_footer = false
            this.hide_header = false
            this.authentication_code = ''
            this.$store.commit('mfa_activated', true)
            this.$store.commit('mfa_enabled', true)
            this.$toast.info(this._('Strong authentication is enabled'))
          } else {
            this.$toast.alert(resp.data.message)
          }
        })
        .catch((err) => {
          this.$log.debug(err)
          this.apiError()
        })
    },
    updateInitials() {
      let initials = ''
      if (this.current_obj.firstname && this.current_obj.firstname.length > 0) {
        initials += this.current_obj.firstname[0]
      }
      if (this.current_obj.lastname && this.current_obj.lastname.length > 0) {
        initials += this.current_obj.lastname[0]
      } else if (
        this.current_obj.firstname &&
        this.current_obj.firstname.length > 1 &&
        this.current_obj.firstname !== ''
      ) {
        initials = initials + this.current_obj.firstname[1]
      }
      this.current_obj.initials = initials.toLocaleUpperCase()
    },
    preFormHandler: function () {
      if (!this.$store.getters.mfa_enabled && this.$store.getters.mfa_activated) {
        this.enabledMfa(this.$store.getters.mfa_activated)
        this.show_secret = true
        this.hide_footer = true
        this.hide_header = true
        this.show_secret_label = this._('Hide secret')
      } else if (this.$store.getters.mfa_enabled && this.$store.getters.mfa_activated) {
        this.show_secret = false
        this.hide_footer = false
        this.hide_header = false
        this.show_secret_label = this._('Show secret')
      }
    },
    preFormSubmit: function (obj) {
      delete obj.id
    },
    postSubmit() {
      this.show_secret = true
      this.show_secret_label = this._('Hide secret')
    },
  },
}
</script>

<style scoped>
.mfa {
  margin-top: 10px;
}
.label-mfa {
  padding-top: 8px;
}
.mfa-secret {
  padding-top: 130px;
}
.mfa-text {
  margin-top: 15px;
  margin-bottom: -30px;
}
.pre-mfa {
  margin-top: 10px;
}
.mfa-button {
  margin-bottom: 10px;
}
.mfa-verified {
  color: #0ca30a;
  margin-top: 10px;
}
</style>

<template>
  <Component
    :is="components[icon]"
    class="nds-icon"
    :style="{
      color,
      stroke: color,
      'font-size': `${size}px`,
      'line-height': `${size}px`,
      width: `${size}px`,
      height: `${size}px`,
    }"
  />
</template>

<script setup lang="ts">
import {
  ArrowDown,
  ArrowDownToLine,
  ArrowLeft,
  ArrowRight,
  ArrowRightToLine,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  CircleAlert,
  EllipsisVertical,
  Globe,
  Info,
  IterationCw,
  Link,
  MessageCircleWarning,
  MessageSquare,
  MessageSquarePlus,
  Pencil,
  Plus,
  SquareArrowOutUpRight,
  SquareCheck,
  Trash2,
  TrendingUp,
  X,
  Play,
  SkipForward,
  LineChart,
  NotebookPen,
  Pen,
  CalendarDays,
  Search,
  CornerDownRight,
  File,
} from 'lucide-vue'
import type { Component, PropType } from 'vue'
import type { NdsIconType } from '@/components/NewDesignSystem/NdsIcon/NdsIcon.interfaces'
import { NdsColor } from '@/interfaces/NdsColors.interfaces'

defineProps({
  icon: {
    type: String as PropType<NdsIconType>,
    required: true,
  },
  color: {
    type: String,
    default: NdsColor.BLACK,
  },
  size: {
    type: Number,
    default: 24,
  },
})

const components: Record<NdsIconType, Component> = {
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  ArrowLeft,
  ArrowRight,
  ArrowDown,
  ArrowDownToLine,
  Plus,
  EllipsisVertical,
  X,
  Pencil,
  Link,
  Globe,
  Check,
  SquareCheck,
  CircleAlert,
  MessageSquarePlus,
  Info,
  SquareArrowOutUpRight,
  Trash2,
  TrendingUp,
  IterationCw,
  ArrowRightToLine,
  MessageCircleWarning,
  MessageSquare,
  Play,
  SkipForward,
  Search,
  LineChart,
  NotebookPen,
  Pen,
  CalendarDays,
  CornerDownRight,
  File,
}
</script>

<style lang="postcss" scoped>
.nds-icon {
  stroke-width: 1.25px;
  min-width: fit-content;
}
</style>
